import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import config from 'ln-liga-os/config/environment';

export default class DateTimeHelper extends Helper {
  @service state;

  @tracked timeoutId = null;

  compute([date], { relative = false }) {
    this.timeoutId = null;
    const momentDate = moment(date);
    moment.locale(this.state.getLanguage());

    if (date && momentDate.isValid()) {
      if (relative) {
        if (moment().diff(momentDate, 'days') < 1) {
          let timeoutSec = 15;
          if (moment().diff(momentDate, 'hours') > 0) {
            timeoutSec = 300;
          }

          this.timeoutId = setTimeout(() => {
            this.recompute();
          }, timeoutSec * 1000);
        }

        if (moment().diff(momentDate, 'hours') < 24) {
          return momentDate.fromNow();
        }
      }

      return momentDate.format(this.getFormat());
    }

    return;
  }

  getFormat() {
    const language = this.state.getLanguage();
    return (
      config.APP.config.formats?.[language]?.date_time ||
      config.APP.config.format?.date_time ||
      'DD-MM-YYYY - HH:mm'
    );
  }

  willDestroy() {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
    }
  }
}
