import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  ...attributes\n  class={{this.className}}\n  {{did-update this.resetState @filter}}\n>\n  <div class=\"search\">\n    <SearchInput @value={{this.searchText}} @onChange={{this.setSearchText}} />\n  </div>\n\n  <div class=\"status-message\">\n    {{#if this.isLoading}}\n      <LoadingSpinner data-test-element=\"loading-spinner\" />\n    {{/if}}\n  </div>\n\n  <div class=\"list\">\n    <ContactList::Collection class=\"front\" @items={{this.birthdays}} />\n  </div>\n</div>", {"contents":"<div\n  ...attributes\n  class={{this.className}}\n  {{did-update this.resetState @filter}}\n>\n  <div class=\"search\">\n    <SearchInput @value={{this.searchText}} @onChange={{this.setSearchText}} />\n  </div>\n\n  <div class=\"status-message\">\n    {{#if this.isLoading}}\n      <LoadingSpinner data-test-element=\"loading-spinner\" />\n    {{/if}}\n  </div>\n\n  <div class=\"list\">\n    <ContactList::Collection class=\"front\" @items={{this.birthdays}} />\n  </div>\n</div>","moduleName":"ln-liga-os/components/communicator/tab-birthdays.hbs","parseOptions":{"srcName":"ln-liga-os/components/communicator/tab-birthdays.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import moment from 'moment';
import { loc } from '@ember/string';

export default class CommunicatorTabBirthdaysComponent extends Component {
  @service api;

  @tracked birthdays = [];

  @tracked birthdaysLoaded = false;

  @tracked filter = null;

  @tracked isContentVisible = false;

  @tracked isLoading = false;

  @tracked lastSubhead = null;

  @tracked offset = 0;

  @tracked perPage = 50;

  @tracked searchText = '';

  @tracked showBirthday = true;

  constructor(...args) {
    super(...args);
    this.loadMore();
  }

  get className() {
    const result = [
      'communicator-tab-base-component',
      'communicator-tab-birthdays-component',
    ];
    if (this.isLoading) {
      result.push('is-loading');
    }
    if (this.isContentVisible) {
      result.push('is-content-visible');
    }
    return result.join(' ');
  }

  get filterQuery() {
    const fromDate = moment();
    let tillDate = moment().add(14, 'days');

    if (this.args.filter === 'today') {
      tillDate = fromDate;
    } else if (this.args.filter === 'coming') {
      fromDate.add(1, 'days');
    }

    return {
      active: true,
      birth_date_from: fromDate.format('MM-DD'),
      birth_date_till: tillDate.format('MM-DD'),
      search_text: this.searchText,
      sort: 'birth_date',
    };
  }

  loadMore() {
    if (this.isLoading || this.birthdaysLoaded) {
      return;
    }

    this.isLoading = true;

    const query = this.filterQuery;

    this.api.read('myliga', ['liga3000_contacts', query]).then((response) => {
      if (this.isDestroyed) {
        return;
      }

      const birthdays = this.parseBirthdays(response.result);
      if (birthdays.length === 0) {
        birthdays.push({
          type: 'no_results',
        });
      }
      this.birthdays = [...this.birthdays, ...birthdays];
      this.isLoading = false;
      this.isContentVisible = true;
      this.birthdaysLoaded = true;
    });
  }

  parseBirthdays(birthdays) {
    const parsedBirthdays = [];
    const today = moment().format('DD-MM');

    birthdays.forEach((birthday) => {
      let subhead = loc('Upcoming');
      let hasBirthday = false;
      const birthdayFormated = moment(birthday.birth_date).format('DD-MM');

      if (birthdayFormated === today) {
        hasBirthday = true;
        subhead = loc('Today');
      }

      if (subhead !== this.lastSubhead) {
        parsedBirthdays.push({
          type: 'subhead',
          text: subhead,
        });

        this.lastSubhead = subhead;
      }

      parsedBirthdays.push({
        birthday: birthdayFormated,
        contact: birthday,
        detailsOpen: false,
        hasBirthday,
        type: 'contact',
      });
    });

    return parsedBirthdays;
  }

  @action
  setSearchText(value) {
    this.searchText = value;
    this.resetState();
  }

  @action
  resetState() {
    this.birthdays = [];
    this.birthdaysLoaded = false;
    this.lastSubhead = null;
    this.loadMore();
  }
}
