import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes class={{this.className}} {{css-props this.cssProps}}>\n  {{#if @error}}\n    <ErrorOverlay @title={{@error.title}} @description={{@error.description}} />\n  {{else}}\n    <iframe\n      class=\"app-frame\"\n      scrolling=\"yes\"\n      frameborder=\"0\"\n      allowtransparency=\"true\"\n      allow=\"geolocation\"\n      title=\"content\"\n      {{did-insert this.initIframe}}\n    />\n  {{/if}}\n</div>", {"contents":"<div ...attributes class={{this.className}} {{css-props this.cssProps}}>\n  {{#if @error}}\n    <ErrorOverlay @title={{@error.title}} @description={{@error.description}} />\n  {{else}}\n    <iframe\n      class=\"app-frame\"\n      scrolling=\"yes\"\n      frameborder=\"0\"\n      allowtransparency=\"true\"\n      allow=\"geolocation\"\n      title=\"content\"\n      {{did-insert this.initIframe}}\n    />\n  {{/if}}\n</div>","moduleName":"ln-liga-os/components/app-content.hbs","parseOptions":{"srcName":"ln-liga-os/components/app-content.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { scheduleOnce } from '@ember/runloop';
import { action } from '@ember/object';
import { PREFIX_QUERY_PARAM_NAME } from 'ln-ember-toolkit/utils/liga-os';

export default class AppContentComponent extends Component {
  @service navigation;
  @service state;

  @tracked iframeElement;

  constructor(...args) {
    super(...args);
    this.updateFrameUrl();
    this.navigation.on('open-url', this, this.updateFrameUrl);
  }

  get className() {
    const result = ['app-content-component'];
    if (this.args.loaded) {
      result.push('loaded');
    }
    if (this.state.communicatorIsOpen) {
      result.push('communicator-is-open');
    }
    if (this.showBackground) {
      result.push('show-background');
    }
    return result.join(' ');
  }

  get cssProps() {
    const backgroundColor = this.showBackground
      ? this.navigation.currentApp.background
      : 'transparent';
    return { 'background-color': backgroundColor };
  }

  get showBackground() {
    return !!(
      this.state.appLoaded &&
      !this.state.appError &&
      this.navigation.currentApp?.background
    );
  }

  @action
  initIframe(element) {
    this.iframeElement = element;
  }

  @action
  updateFrameUrl() {
    if (!this.navigation.currentUrl) {
      return;
    }

    scheduleOnce(
      'afterRender',
      this,
      this._updateFrameUrl,
      this.navigation.currentUrl
    );
  }

  _updateFrameUrl(url) {
    if (!this.iframeElement || this.isDestroying) {
      return;
    }

    const { origin } = location;
    const fullUrl = url.match(/^\/[^/]/) ? `${origin}${url}` : url;
    const iframeUrl = new URL(fullUrl);

    const app = this.navigation.getAppFor(url);
    if (app) {
      const ligaOsPrefix = `${origin}/#/!/${app.slug}`;
      iframeUrl.searchParams.append(PREFIX_QUERY_PARAM_NAME, ligaOsPrefix);
    }

    const appFrame = this.iframeElement;
    appFrame && appFrame.setAttribute('src', iframeUrl.toString());
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.navigation.off('open-url', this, this.updateFrameUrl);
  }
}
