import { isNone } from '@ember/utils';
import Service, { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/string';
import ENV from '../config/environment';
import config from 'ln-liga-os/config/environment';
import { tracked } from '@glimmer/tracking';

function assetURL(path) {
  const { protocol, host } = location;
  const {
    APP: { version: appVersion },
    rootURL,
  } = ENV;

  return path
    ? `${protocol}//${host}${rootURL}assets/${path}?v=${appVersion}`
    : null;
}

export default class ClientThemeService extends Service {
  @service api;

  @tracked defaultData = null;

  @tracked themeData = null;

  constructor(...args) {
    super(...args);
    this.defaultData = {
      background_image: {
        preview_url: null,
      },
      logo_image: {
        preview_url: assetURL('images/svg/vdc-software-logo-white.svg'),
      },
      title: config.APP.config.clientTheme?.defaultTitle || 'LIGAOS',
      type: config.APP.config.clientTheme?.defaultType || 'LIGANOVA',
    };
  }

  get className() {
    return `theme-${this.data.type.toLowerCase()}`;
  }

  get data() {
    const defaultData = { ...this.defaultData };
    if (!this.themeData) {
      return defaultData;
    }

    const themeData = Object.keys(this.themeData).reduce((themeData, key) => {
      const value = this.themeData?.[key];
      if (!isNone(value)) {
        themeData[key] = value;
      }
      return themeData;
    }, {});

    return { ...defaultData, ...themeData };
  }

  get favicon() {
    const name = this.data.type.toLowerCase();
    return ['Dark', 'Light'].includes(name) ? 'default' : name;
  }

  get isDefault() {
    return ['Dark', 'Light', 'AMG'].includes(this.data.type);
  }

  get style() {
    let {
      background_image: { preview_url: bgURL },
      logo_image: { preview_url: logoURL },
    } = this.data || {};

    if (this.data.type === 'LIGANOVA') {
      logoURL = assetURL('themes/liganova/images/logo-ligaos.svg');
    }

    if (this.data.type === 'Dark') {
      logoURL = assetURL('images/svg/vdc-software-logo-white.svg');
    }

    if (this.data.type === 'AMG') {
      return undefined;
    }

    const styles = [];

    if (bgURL) {
      styles.push(`
        .background-container, .startup-mask-component {
          background-image: url('${bgURL}') !important;
        }
      `);
    }

    styles.push(`
      .logo {
        background-image: url('${logoURL}') !important;
      }
    `);

    return htmlSafe(styles.join('\n'));
  }

  get support() {
    const type = this.data.type.toLowerCase();
    return (
      config.APP.config.support?.[type] ||
      config.APP.config.support?.default ||
      {}
    );
  }

  load() {
    const { search, hostname } = location;
    const [, domain] = search.match(/domain=([^&]+)/) || [];

    return this.api
      .read('myliga', [
        'themes',
        { domain: domain || hostname, paging_limit: 1 },
      ])
      .then(({ result: [theme] }) => theme)
      .catch((error) =>
        console.error(
          `GET /themes: ${error.message || error.responseText || error}`
        )
      )
      .then((theme) => {
        this.themeData = theme;
      })
      .then(() => this.setup());
  }

  setup() {
    const faviconElement = document.querySelector('#favicon');
    faviconElement &&
      faviconElement.setAttribute(
        'href',
        assetURL(`images/favicons/${this.favicon}.ico`)
      );
  }
}
