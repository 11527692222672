import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class LoginRoute extends Route {
  @service router;
  @service session;
  @service state;

  beforeModel() {
    return this.session
      .checkAuth()
      .then(() => {
        if (this.session.isLoggedIn) {
          return this.router.transitionTo('/!');
        }
      })
      .catch(() => {
        if (this.session.isLoggedIn) {
          return this.router.transitionTo('/!');
        }

        // Otherwise, swallow the error.
        // If the user is not logged in, they should be able to see the login screen.
      });
  }

  activate() {
    // In some very rare cases appLoaded is set to true when the user lands on the login screen
    // That leads to a missing class name in the application view which messes up the login layout
    // comes from login route, initially commited by JG
    this.state.appLoaded = false;
    this.state.appLocation = null;
    this.state.resetTitle();
  }

  setupController(controller) {
    controller.showPasswordReset = false;
    controller.showPasswordResetRequested = false;
  }
}
