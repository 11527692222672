import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes class=\"communicator-error-message-component\">\n  <span class=\"close-icon\" {{on \"click\" this.remove passive=true}} role=\"button\"></span>\n  {{@message}}\n</div>\n", {"contents":"<div ...attributes class=\"communicator-error-message-component\">\n  <span class=\"close-icon\" {{on \"click\" this.remove passive=true}} role=\"button\"></span>\n  {{@message}}\n</div>\n","moduleName":"ln-liga-os/components/communicator/error-message.hbs","parseOptions":{"srcName":"ln-liga-os/components/communicator/error-message.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class ErrorMessageComponent extends Component {
  @action
  remove() {
    return this.args.remove?.();
  }
}
