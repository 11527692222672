import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import BaseMicroMenuService from 'ln-ember-contact-menu/services/micro-menu';

export default class MicroMenuService extends BaseMicroMenuService {
  @service state;

  @tracked horizontalAttachement = 'left';

  get session() {
    return this.state.session;
  }
}
