import EmberRouter from '@ember/routing/router';
import config from 'ln-liga-os/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('login');
  this.route('password-reset', { path: '/password-reset/:token' });
  this.route('main', { path: '/*url' });
});
