import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes class=\"contact-list-item-absence-component\">\n  <ResizedImage\n    @classNames=\"user-image round\"\n    @placeholder=\"assets/images/user.svg\"\n    @size=\"40x40\"\n    @src={{this.contact.picture.preview_url}}\n    {{on \"click\" (fn this.openMenu (component \"contact-menu\" userId=this.contact.user_id))}}\n  />\n\n  <p>\n    <span class=\"name\">{{this.contact.first_name}} {{this.contact.name}}</span><br>\n    <span class=\"reason\">{{@item.reason}}</span>\n  </p>\n\n  <div class=\"absence-type\n    {{if @item.isIll \"is-ill\"}}\n    {{if @item.isHoliday \"is-holiday\"}}\"\n  />\n</div>", {"contents":"<div ...attributes class=\"contact-list-item-absence-component\">\n  <ResizedImage\n    @classNames=\"user-image round\"\n    @placeholder=\"assets/images/user.svg\"\n    @size=\"40x40\"\n    @src={{this.contact.picture.preview_url}}\n    {{on \"click\" (fn this.openMenu (component \"contact-menu\" userId=this.contact.user_id))}}\n  />\n\n  <p>\n    <span class=\"name\">{{this.contact.first_name}} {{this.contact.name}}</span><br>\n    <span class=\"reason\">{{@item.reason}}</span>\n  </p>\n\n  <div class=\"absence-type\n    {{if @item.isIll \"is-ill\"}}\n    {{if @item.isHoliday \"is-holiday\"}}\"\n  />\n</div>","moduleName":"ln-liga-os/components/contact-list/item-absence.hbs","parseOptions":{"srcName":"ln-liga-os/components/contact-list/item-absence.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class ContactListItemAbsenceComponent extends Component {
  @service microMenu;

  get contact() {
    return this.args.item.contact;
  }

  @action
  openMenu(component, event) {
    event.preventDefault();
    this.microMenu.open(event.target.id, component);
  }
}
