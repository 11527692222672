import { tracked } from '@glimmer/tracking';
import { addObserver } from '@ember/object/observers';
import { isNone } from '@ember/utils';
import Evented from '@ember/object/evented';
import { action, computed } from '@ember/object';
import * as Sentry from '@sentry/browser';
import RSVP from 'rsvp';
import Service, { inject as service } from '@ember/service';
import Ember from 'ember';
import setIfChanged from '../utils/set-if-changed';
import playAudio from 'ln-liga-os/utils/play-audio';
import config from 'ln-liga-os/config/environment';

const notificationSoundSrc = 'assets/audio/notification.mp3';

export default class StateService extends Service.extend(Evented) {
  @service apiEvents;
  @service clientTheme;
  @service communicator;
  @service rights;
  @service settings;
  @service storage;
  @service store;

  @tracked appCount = 0;

  @tracked appError = null;

  @tracked appLoaded = false;

  @tracked appTitle = null;

  @tracked appLocation = false;

  @tracked applicationsError = undefined;

  @tracked communicatorIsOpen = false;

  @tracked isBlurred = undefined;

  @tracked languages = [];

  @tracked session = null;

  @tracked showClickCatcherMask = false;

  @tracked user = null;

  constructor(...args) {
    super(...args);
    addObserver(this, 'titleCount', this.updateTitle);
  }

  get showLoaderMask() {
    return !this.appLoaded;
  }

  get showStartupMask() {
    return !this.session || !this.appLoaded;
  }

  @computed('communicator.badges.{notifications,tasks}')
  get notificationsCount() {
    return (
      (Number(this.communicator.badges?.notifications) || 0) +
      (Number(this.communicator.badges?.tasks) || 0)
    );
  }

  @computed('appCount', 'communicator.badgeNumber', 'notificationsCount')
  get titleCount() {
    if (this.appCount) {
      return this.appCount + (this.notificationsCount || 0);
    }

    return this.communicator.badgeNumber;
  }

  loadLanguages() {
    if (!this.languagesPromise) {
      this.languagesPromise = this.store
        .query('myliga/language', {
          active: true,
          native_spelling: true,
        })
        .then((languages) => {
          this.languages = languages.slice();
        });
    }
    return this.languagesPromise.then(() => {
      return this.languages;
    });
  }

  reset() {
    this.user = null;
    this.appError = null;
    this.appLoaded = false;
    this.appTitle = null;
    this.appCount = 0;
    this.communicator.reset();
    this.rights.reset();
    this.updateTitle();
  }

  resetTitle() {
    this.appCount = 0;
    this.appTitle = null;
  }

  // Called in application route
  restore() {
    this.setSession(this.storage.get('ln-liga-os:session', null));
    this.apiEvents.triggerLanguageChange(this.getLanguage());
    this.updateStrings();
    this.updateTitle();
  }

  loadUser(force = false) {
    if (!force && Number(this.user?.id) === this.session?.user_id) {
      return RSVP.resolve();
    }

    if (isNone(this.session?.user_id)) {
      return RSVP.reject(new Error('session.user_id is not set'));
    }

    return this.store
      .findRecord('myliga/user', this.session.user_id)
      .then((user) => (this.user = user))
      .then(() => this.loadLanguages())
      .then((languages) => {
        const language = languages.find(
          ({ id }) => `${id}` === String(this.user.language_id)
        );
        if (language) {
          this.setLanguage(language.get('code'), false);
        }
      });
  }

  setSession(session) {
    if (setIfChanged(this, 'session', session)) {
      this.storage.set('ln-liga-os:session', session);
      this.apiEvents.triggerSessionChange(session);

      // Set Sentry user context
      if (session) {
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: `${session.user_id}`,
          });
        });
      }
    }
  }

  setLanguage(code, reload = true) {
    if (code !== this.getLanguage()) {
      this.storage.set('ln-liga-os:language', code);
      this.apiEvents.triggerLanguageChange(code);
      this.updateStrings();

      if (reload) {
        this.trigger('language-did-change', code);
      }
    }
  }

  getLanguage() {
    return this.storage.get('ln-liga-os:language') || this.detectLanguage();
  }

  setAppLoaded(appLoaded) {
    setIfChanged(this, 'appLoaded', appLoaded);
  }

  setAppTitle(appTitle = null) {
    // TODO remove appTitle on app switch!
    if (setIfChanged(this, 'appTitle', appTitle)) {
      this.updateTitle();
    }
  }

  setTitleCount(count = 0) {
    // TODO remove prefix on app switch!
    if (setIfChanged(this, 'appCount', count)) {
      this.updateTitle();
    }
  }

  showClickCatcher() {
    this.showClickCatcherMask = true;
    this.trigger('show-click-catcher');
  }

  hideClickCatcher() {
    if (this.showClickCatcherMask) {
      this.showClickCatcherMask = false;
      this.trigger('hide-click-catcher');
    }
  }

  toogleNavigationIsOpen() {
    this.setNavigationIsOpen(!this.navigationIsOpen);
  }

  setNavigationIsOpen(open) {
    if (setIfChanged(this, 'navigationIsOpen', open)) {
      if (open) {
        this.showClickCatcher();
      } else {
        this.hideClickCatcher();
      }
    }
    // TODO trigger event?
  }

  toogleCommunicatorIsOpen() {
    this.communicatorIsOpen = !this.communicatorIsOpen;
  }

  setCommunicatorIsOpen(open) {
    this.communicatorIsOpen = open;
    if (open) {
      this.showClickCatcher();
    } else {
      this.hideClickCatcher();
    }
    // TODO trigger event?
  }

  propertyHasChanged(key, value) {
    return JSON.stringify(this[key]) !== JSON.stringify(value);
  }

  triggerNotificationSound() {
    this.settings.getValue('NotificationSound').then((enabled) => {
      if (enabled) {
        playAudio(notificationSoundSrc);
      }
    });
  }

  triggerAppErrorReset() {
    if (this.appError) {
      this.appError = null;
    }
  }

  triggerAppError(error) {
    this.appError = error;
    this.setAppLoaded(true);
  }

  detectLanguage() {
    const { languages = [] } = window.navigator || {};
    return languages.find((code) => Object.keys(config.STRINGS).includes(code));
  }

  updateStrings() {
    Ember.STRINGS = config.STRINGS[this.getLanguage()] || {};
  }

  @action
  updateTitle() {
    let title = this.clientTheme.data.title || 'LIGAOS';

    if (this.appTitle) {
      title = `${this.appTitle} - ${title}`;
    }

    if (this.titleCount) {
      title = `(${this.titleCount}) ${title}`;
    }

    document.querySelector('title').innerHTML = title;
    document.title = title;
  }
}
