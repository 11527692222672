import HashLocation from '@ember/routing/hash-location';
import { set } from '@ember/object';

export default class CustomHashLocation extends HashLocation {
  implementation = 'custom-hash';

  replaceURL(path) {
    const url = new URL(location.href);
    url.hash = `#${path}`;

    this.location.replace(url);
    set(this, 'lastSetURL', path);
  }
}
