import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes class=\"contact-list-item-mailing-list-component {{if @canEditList 'can-edit-list'}}\">\n  <a href=\"mailto:{{this.list.email}}\" class=\"mail-wrapper\">\n    <span class=\"title\">{{this.list.name}}</span><br />\n    <span class=\"description\">{{this.list.description}}</span>\n    <span class=\"mail\" />\n  </a>\n\n  {{#if @canEditList}}\n    <div class=\"edit\" {{on \"click\" this.editMailingList passive=true}} role=\"button\" />\n  {{/if}}\n</div>", {"contents":"<div ...attributes class=\"contact-list-item-mailing-list-component {{if @canEditList 'can-edit-list'}}\">\n  <a href=\"mailto:{{this.list.email}}\" class=\"mail-wrapper\">\n    <span class=\"title\">{{this.list.name}}</span><br />\n    <span class=\"description\">{{this.list.description}}</span>\n    <span class=\"mail\" />\n  </a>\n\n  {{#if @canEditList}}\n    <div class=\"edit\" {{on \"click\" this.editMailingList passive=true}} role=\"button\" />\n  {{/if}}\n</div>","moduleName":"ln-liga-os/components/contact-list/item-mailing-list.hbs","parseOptions":{"srcName":"ln-liga-os/components/contact-list/item-mailing-list.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class ContactListItemMailingListComponent extends Component {
  get list() {
    return this.args.item.list;
  }

  @action
  editMailingList() {
    this.args.onMailinglistEdit?.(this.args.item);
  }
}
