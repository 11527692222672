import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes class=\"communicator-tab-base-component communicator-tab-applications-component is-content-visible\">\n  {{#if this.state.applicationsError}}\n    <Communicator::ErrorMessage\n      @message={{this.state.applicationsError}}\n      @remove={{this.removeErrorMessage}}\n    />\n  {{/if}}\n\n  {{#each this.navigation.navigations as |item|}}\n    <NavigationItem\n      @item={{item}}\n      data-test-element=\"navigation-item\"\n      {{on \"click\" (fn this.openItem item) passive=true}}\n    />\n  {{/each}}\n</div>", {"contents":"<div ...attributes class=\"communicator-tab-base-component communicator-tab-applications-component is-content-visible\">\n  {{#if this.state.applicationsError}}\n    <Communicator::ErrorMessage\n      @message={{this.state.applicationsError}}\n      @remove={{this.removeErrorMessage}}\n    />\n  {{/if}}\n\n  {{#each this.navigation.navigations as |item|}}\n    <NavigationItem\n      @item={{item}}\n      data-test-element=\"navigation-item\"\n      {{on \"click\" (fn this.openItem item) passive=true}}\n    />\n  {{/each}}\n</div>","moduleName":"ln-liga-os/components/communicator/tab-applications.hbs","parseOptions":{"srcName":"ln-liga-os/components/communicator/tab-applications.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class CommunicatorTabApplicationComponent extends Component {
  @service navigation;
  @service state;

  @action
  openItem({ url }) {
    this.navigation.openUrl(url);
    if (!this.navigation.belongsToExternalApp(url)) {
      this.args.close?.();
    }
  }

  @action
  removeErrorMessage() {
    this.state.applicationsError = null;
  }
}
