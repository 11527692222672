import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes class={{this.className}}>\n  <div class=\"startup-mask-component-footer\">\n    <p>\n      <a href={{this.clientTheme.support.url}} target=\"_blank\" rel=\"noopener noreferrer\">\n        <img class=\"help-icon\" src=\"assets/images/svg/help-white.svg\" alt=\"help-icon\">\n      </a>\n      <a href=\"mailto:{{this.clientTheme.support.email}}\">\n        {{loc \"Help\"}}\n      </a>\n      <span class=\"divider\">|</span>\n      <a href=\"mailto:{{this.clientTheme.support.email}}\">\n        {{loc \"Contact\"}}\n      </a>\n      <span class=\"divider\">|</span>\n      <span>&copy; <a href={{this.appConfig.homepage}} target=\"_blank\" rel=\"noopener noreferrer\">LIGANOVA</a></span>\n    </p>\n  </div>\n</div>", {"contents":"<div ...attributes class={{this.className}}>\n  <div class=\"startup-mask-component-footer\">\n    <p>\n      <a href={{this.clientTheme.support.url}} target=\"_blank\" rel=\"noopener noreferrer\">\n        <img class=\"help-icon\" src=\"assets/images/svg/help-white.svg\" alt=\"help-icon\">\n      </a>\n      <a href=\"mailto:{{this.clientTheme.support.email}}\">\n        {{loc \"Help\"}}\n      </a>\n      <span class=\"divider\">|</span>\n      <a href=\"mailto:{{this.clientTheme.support.email}}\">\n        {{loc \"Contact\"}}\n      </a>\n      <span class=\"divider\">|</span>\n      <span>&copy; <a href={{this.appConfig.homepage}} target=\"_blank\" rel=\"noopener noreferrer\">LIGANOVA</a></span>\n    </p>\n  </div>\n</div>","moduleName":"ln-liga-os/components/startup-mask.hbs","parseOptions":{"srcName":"ln-liga-os/components/startup-mask.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import config from 'ln-liga-os/config/environment';

/**
 * Startup Mask Component
 *
 * @todo Check if we need this component
 * The component seems to be outdated and has its content
 * always rendered with "display: none"
 * @see "app/styles/components/_startup-mask.scss"
 */
export default class StartupMaskComponent extends Component {
  @service clientTheme;
  @service session;
  @service state;

  appConfig = config.APP.config;

  get className() {
    const result = ['startup-mask-component', this.clientTheme.className];
    if (this.args.show) {
      result.push('show');
    }
    if (this.session.isLoggedIn) {
      result.push('logged-in');
    }
    result.push(this.state.appLoaded ? 'app-loaded' : 'app-not-loaded');
    return result.join(' ');
  }
}
