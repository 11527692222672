import Evented from '@ember/object/evented';
import Service, { inject as service } from '@ember/service';
import io from 'socket.io-client';
import config from 'ln-liga-os/config/environment';

export default class PubSubService extends Service.extend(Evented) {
  @service session;

  config = config.APP.config;

  subscribe() {
    if (this.socket) {
      this.unsubscribe();
    }

    const transports = this.config['ln-pub-sub-service']?.transports || [
      'websocket',
      'polling',
    ];
    const serviceUrl = this.config['ln-pub-sub-service']?.url;
    if (!serviceUrl) {
      console.error(
        '[PubSub] Configuration for `ln-pub-sub-service.url` missing!'
      );
      return;
    }

    const token = this.session.token;

    if (!token) {
      console.error('[PubSub] Session missing!');
      return;
    }

    const { url, path } = this.splitUrl(serviceUrl);

    this.socket = io(url, { path, forceNew: true, transports });

    this.socket.on('connect', () => {
      this.socket.emit('authentication', { token });
    });

    this.socket.on('event', (event) => {
      this.trigger('event', event);
      if (this.config.log?.pubSub?.events) {
        console.log('[PubSub] event', JSON.stringify(event));
      }
    });

    this.socket.on('error', (error) => console.error('[PubSub]', error));
  }

  unsubscribe() {
    if (!this.socket) {
      return;
    }

    this.socket.disconnect();
    this.socket = null;
  }

  splitUrl(serviceUrl) {
    const url = new URL(serviceUrl);
    const path =
      url.pathname === '/'
        ? '/socket.io'
        : `${url.pathname.replace(/\/$/, '')}/socket.io`;

    url.pathname = '';

    return { url: url.toString(), path };
  }
}
