import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Ember from 'ember';
import config from 'ln-liga-os/config/environment';

export default class ApplicationRoute extends Route {
  @service api;
  @service apiEvents;
  @service clientTheme;
  @service ligaOsApi;
  @service navigation;
  @service state;

  constructor(...args) {
    super(...args);

    this.apiEvents.triggerConfigChange(config.APP.config);

    this.ligaOsApi.register();

    if (config.APP.config.exposeLigaOSApi) {
      window.LIGAOS_API = this.ligaOsApi;
    }

    if (config.APP.config.exposeApi) {
      window.API = this.api;
    }
  }

  beforeModel() {
    return this.clientTheme.load().then(() => this.state.restore());
  }

  model() {
    const langCode = this.ligaOsApi.getLanguage();
    Ember.STRINGS = config.STRINGS[langCode] || {};
  }
}
