import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  ...attributes\n  class=\"contact-list-item-client-component\"\n  role=\"button\"\n  {{on \"click\" this.onClientClick passive=true}}\n>\n  <span class=\"client-name\">{{this.client.company_name}}</span>\n  <span class=\"toggle {{if @item.isOpen \"is-open\"}}\"></span>\n</div>", {"contents":"<div\n  ...attributes\n  class=\"contact-list-item-client-component\"\n  role=\"button\"\n  {{on \"click\" this.onClientClick passive=true}}\n>\n  <span class=\"client-name\">{{this.client.company_name}}</span>\n  <span class=\"toggle {{if @item.isOpen \"is-open\"}}\"></span>\n</div>","moduleName":"ln-liga-os/components/contact-list/item-client.hbs","parseOptions":{"srcName":"ln-liga-os/components/contact-list/item-client.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class ContactListItemClientComponent extends Component {
  get client() {
    return this.args.item.client;
  }

  @action
  onClientClick() {
    this.args.onClientClick?.(this.args.item);
  }
}
