import { helper } from '@ember/component/helper';
import sanitizeHtml from 'sanitize-html';
import { htmlSafe } from '@ember/string';

export function sanitize([text = ''], { allowedTags = '' }) {
  allowedTags = allowedTags.split(' ').filter((tag) => tag);
  return htmlSafe(sanitizeHtml(text, { allowedTags }));
}

export default helper(sanitize);
