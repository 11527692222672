import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class PasswordResetRoute extends Route {
  @service router;
  @service session;
  @service state;

  beforeModel() {
    if (this.session.isLoggedIn) {
      return this.router.transitionTo('/!');
    }
  }

  model({ token }) {
    return {
      token,
    };
  }

  activate() {
    // In some very rare cases appLoaded is set to true when the user lands on the this screen
    // That leads to a missing class name in the application view which messes up the reset pass layout
    this.state.appLoaded = false;
    this.state.appLocation = null;
    this.state.resetTitle();
  }
}
