import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { toLabel } from 'ln-ember-toolkit/utils/string-utils';
import { loc } from '@ember/string';
import { computed } from '@ember/object';
import { getOwner, setOwner } from '@ember/application';
import config from 'ln-liga-os/config/environment';

export default class CommunicatorTabItem {
  @service communicator;
  @service rights;
  @service state;

  @tracked active;
  @tracked filterOptions;
  @tracked filterValue;
  @tracked hasRight;
  @tracked hasTitle;
  @tracked identifier;
  @tracked rendered;
  @tracked target;

  config = config.APP.config;

  constructor(container, args) {
    setOwner(this, getOwner(container));
    this.filterOptions = args.filterOptions;
    this.filterValue = args.filterValue;
    this.hasRight = args.hasRight ?? false;
    this.hasTitle = args.hasTitle;
    this.identifier = args.identifier ?? null;
    this.target = args.target;
    if (!this.hasRight) {
      this.rights
        .can('OTHER', `ligaos.communicator.${this.identifier}.read`)
        .then((permission) => {
          this.hasRight = permission;
        });
    }
  }

  get name() {
    return toLabel(this.identifier);
  }

  get title() {
    if (this.identifier === 'settings') {
      return this.state.user?.get('fullName');
    }
    return loc(this.name);
  }

  get hasAccess() {
    return (
      this.hasRight && !this.config.disable[`communicator_${this.identifier}`]
    );
  }

  @computed('communicator.badges', 'identifier')
  get badge() {
    return this.communicator.badges?.[this.identifier];
  }
}
