import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { run } from '@ember/runloop';
import { isSupportedBrowser, formatSupportedBrowsers } from '../utils/browser';
import config from 'ln-liga-os/config/environment';

export default class ApplicationController extends Controller {
  @service clientTheme;
  @service microMenu;
  @service state;

  @tracked browserIsSupported;

  @tracked browserSupportedMsg;

  constructor(...args) {
    super(...args);

    // TODO is there a better solution for this?
    // Set Class `resizing` to disable animations on resizing
    let resizeTimer;
    window.addEventListener(
      'resize',
      run.bind(this, () => {
        clearTimeout(resizeTimer);
        document.body.classList.add('resizing');
        resizeTimer = setTimeout(
          () => document.body.classList.remove('resizing'),
          10
        );
      })
    );

    const onOrientationChange = () => {
      // Set the body height, so that mobile browsers don't overlay content with
      // their UI.
      document.body.style.height = '100vh';
      if (window.innerHeight !== document.body.offsetHeight) {
        document.body.style.height = window.innerHeight;
      }
    };

    window.addEventListener('resize', onOrientationChange);
    window.addEventListener('orientationchange', onOrientationChange);
    onOrientationChange();

    const supportedBrowsers = config.APP.config.supportedBrowsers;
    this.browserIsSupported =
      !supportedBrowsers || isSupportedBrowser(supportedBrowsers);
    this.browserSupportedMsg = formatSupportedBrowsers(supportedBrowsers ?? []);
  }

  get isAppLoaded() {
    return this.state.appLoaded;
  }
}
