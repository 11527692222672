import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  ...attributes\n  class={{this.className}}\n  {{did-update this.resetState @filter}}\n>\n  <div class=\"search\">\n    <SearchInput @value={{this.searchText}} @onChange={{this.setSearchText}} />\n  </div>\n\n  <div class=\"status-message\">\n    {{#if this.isLoading}}\n      <LoadingSpinner data-test-element=\"loading-spinner\" />\n    {{else}}\n      {{#if (eq this.formattedAbsences.length 0)}}\n        <div class=\"list-message\">[ {{loc \"No entries\"}} ]</div>\n      {{/if}}\n    {{/if}}\n  </div>\n\n  <div class=\"list\">\n    <ContactList::Collection\n      @items={{this.formattedAbsences}}\n      @loadNextPage={{this.loadMore}}\n      class=\"front\"\n    />\n  </div>\n</div>", {"contents":"<div\n  ...attributes\n  class={{this.className}}\n  {{did-update this.resetState @filter}}\n>\n  <div class=\"search\">\n    <SearchInput @value={{this.searchText}} @onChange={{this.setSearchText}} />\n  </div>\n\n  <div class=\"status-message\">\n    {{#if this.isLoading}}\n      <LoadingSpinner data-test-element=\"loading-spinner\" />\n    {{else}}\n      {{#if (eq this.formattedAbsences.length 0)}}\n        <div class=\"list-message\">[ {{loc \"No entries\"}} ]</div>\n      {{/if}}\n    {{/if}}\n  </div>\n\n  <div class=\"list\">\n    <ContactList::Collection\n      @items={{this.formattedAbsences}}\n      @loadNextPage={{this.loadMore}}\n      class=\"front\"\n    />\n  </div>\n</div>","moduleName":"ln-liga-os/components/communicator/tab-absences.hbs","parseOptions":{"srcName":"ln-liga-os/components/communicator/tab-absences.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment';
import { loc } from '@ember/string';

export default class CommunicatorTabAbsencesComponent extends Component {
  @service api;

  @tracked absences = [];

  @tracked filter = 'all';

  @tracked isContentVisible = false;

  @tracked isLoading = null;

  @tracked offset = 0;

  @tracked perPage = 50;

  @tracked requestedForOffset = null;

  @tracked searchText = '';

  constructor(...args) {
    super(...args);
    this.loadMore();
  }

  get className() {
    const result = [
      'communicator-tab-base-component',
      'communicator-tab-absences-component',
    ];
    if (this.isLoading) {
      result.push('is-loading');
    }
    if (this.isContentVisible) {
      result.push('is-content-visible');
    }
    return result.join(' ');
  }

  get formattedAbsences() {
    let formattedAbsences = [
      {
        type: 'subhead',
        text: loc('Today'),
      },
      {
        type: 'subhead',
        text: loc('Upcoming'),
      },
    ];

    let todayAbsences = false;

    (this.absences || []).forEach((absence) => {
      const start = moment(absence.date_from).format('DD-MM');
      const end = moment(absence.date_till).format('DD-MM');
      let text = start;

      if (start !== end) {
        text += ` ${loc('to')} ${end}`;
      }

      if (absence.reason) {
        text += ` | ${absence.reason}`;
      }

      const formatedAbsence = {
        detailsOpen: false,
        type: 'absence',
        contact: absence,
        reason: text,
        isIll: absence.type === 'Sickness',
        isHoliday: absence.type === 'Holiday',
      };

      // Sorting absences into Today/Upcoming
      if (moment(absence.date_from).isBefore(moment().add(1, 'days'), 'day')) {
        const upcomingIndex = formattedAbsences.findIndex(
          ({ text }) => text === loc('Upcoming')
        );
        formattedAbsences.splice(upcomingIndex, 0, formatedAbsence);
        todayAbsences = true;
      } else {
        formattedAbsences.push(formatedAbsence);
      }
    });

    // Removing trailing 'Upcoming' in case there are 0 upcoming absences.
    if (formattedAbsences[formattedAbsences.length - 1].type === 'subhead') {
      formattedAbsences.pop();
    }

    // Remove 'Today' in case there are 0 absences today.
    if (!todayAbsences) {
      formattedAbsences.shift();
    }

    // Removing default Today/Upcoming objects from array in case there are no absences at all.
    if (formattedAbsences.length === 1) {
      formattedAbsences = [];
    }

    return formattedAbsences;
  }

  @action
  loadMore() {
    if (this.isLoading || this.requestedForOffset === this.offset) {
      return;
    }

    this.isLoading = true;

    const query = {
      paging_limit: this.perPage,
      paging_offset: this.offset,
    };
    if (this.args.filter !== 'all') {
      // Turn filter into an array because `/absences` can receive multiple type params.
      query.type = this.args.filter.split(',');
    }

    if (this.searchText) {
      query.search_text = this.searchText;
    }

    this.api
      .read('myliga', ['communicator/absences', query])
      .then((response) => {
        if (this.isDestroying) {
          return;
        }

        this.absences = [...this.absences, ...response.result];
        this.requestedForOffset = this.offset;
        this.offset += response.meta.count;
        this.isContentVisible = true;
        this.isLoading = false;
      });
  }

  @action
  resetState() {
    this.absences = [];
    this.offset = 0;
    this.requestedForOffset = null;
    this.loadMore();
  }

  @action
  setSearchText(value) {
    this.searchText = value;
    this.resetState();
  }
}
