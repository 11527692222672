export default function playAudio(src) {
  const audioElement = window.document.createElement('audio');
  audioElement.style.display = 'none';
  audioElement.src = src;
  audioElement.addEventListener('canplaythrough', onAudioReady, { once: true });
  audioElement.addEventListener('ended', onPlaybackEnded, { once: true });
  document.body.appendChild(audioElement);
}

function onAudioReady(event) {
  event.target.currentTime = 0;
  event.target.play();
}

function onPlaybackEnded(event) {
  document.body.removeChild(event.target);
}
