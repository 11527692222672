import BaseApiService from 'ln-ember-api-service/services/api';
import { inject as service } from '@ember/service';

export default class ApiService extends BaseApiService {
  @service session;

  loadToken() {
    return this.session.token;
  }
}
