import setupDeprecationWorkflow from 'ember-cli-deprecation-workflow';

setupDeprecationWorkflow({
  throwOnUnhandled: true,
  workflow: [
    {
      handler: 'silence',
      matchId: 'ember-string.loc',
    },
    {
      handler: 'silence',
      matchId: 'this-property-fallback',
    },
    {
      handler: 'silence',
      matchId: 'autotracking.mutation-after-consumption',
    },
    {
      handler: 'silence',
      matchId: 'manager-capabilities.modifiers-3-13',
    },
    {
      handler: 'silence',
      matchId: 'array-observers',
    },
    {
      handler: 'silence',
      matchId: 'ember.built-in-components.legacy-attribute-arguments',
    },
    {
      handler: 'silence',
      matchId: 'routing.transition-methods',
    },
  ],
});
