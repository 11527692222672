import isOffline from './is-offline';
import Keycloak from 'keycloak-js';

class KeycloakFake {
  constructor() {
    this._nextToken = null;
    this._token = 'fake-keycloak-token';
    this.__reset();
  }

  // test helpers
  __reset() {
    this.authorized = true;
    this.didInitialize = false;
    this.log = [];
  }

  __setAuthorized(authorized) {
    this.authorized = authorized;
  }

  __setToken(value) {
    this._token = value;
  }

  // keycloak methods
  init(args) {
    if (this.didInitialize) {
      throw new Error('Keycloak is already initialized');
    }

    this.didInitialize = true;

    this.log.push([
      'init',
      {
        onLoad: args.onLoad,
      },
    ]);

    return Promise.resolve(this.authorized);
  }

  logout(options = {}) {
    this.log.push(['logout', options]);
    this.authorized = false;

    return Promise.resolve();
  }

  updateToken() {
    this.log.push(['updateToken']);

    return Promise.resolve().then(() => {
      this._token = this._nextToken;
      this._nextToken = null;
    });
  }

  login() {
    this.log.push(['login']);
    this.authorized = true;

    return Promise.resolve();
  }

  get authenticated() {
    return this.log.length > 0 && this.authorized;
  }

  get token() {
    return this.authorized ? this._token : null;
  }
}

const keycloak = isOffline() ? KeycloakFake : Keycloak;

export default keycloak;
