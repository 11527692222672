import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class ClickCatcherComponent extends Component {
  @service mediaMatcher;
  @service state;

  parentElement;

  @action
  fadeOut() {
    if (!this.mediaMatcher.isMobile) {
      return;
    }
    const clone = document.createElement('div');
    clone.className = 'click-catcher-component destroying';
    clone.addEventListener(
      'animationend',
      () => {
        clone.remove();
      },
      { once: true }
    );
    this.parentElement.appendChild(clone);
    this.parentElement = null;
  }

  @action
  handleClick() {
    this.state.hideClickCatcher();
  }

  @action
  registerElement(element) {
    this.parentElement = element.parentElement;
  }
}
