function safeLoadScript(src) {
  return new Promise((resolve) => {
    let script = document.querySelector(`script[src="${src}"]`);
    if (script) {
      resolve(undefined);
    } else {
      script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve(undefined);
      document.head.appendChild(script);
    }
  });
}

function initialize(application) {
  const isEnabled = application.get('config.disable.googleAnalytics') !== true;
  const tagManagerId = application.get('config.googleTagManagerId');

  if (isEnabled && tagManagerId) {
    const src = `https://www.googletagmanager.com/gtag/js?id=${tagManagerId}`;
    safeLoadScript(src).then(() => {
      window.dataLayer = window.dataLayer || [];
      function gtag(...args) {
        window.dataLayer.push(args);
      }
      gtag('js', new Date());
      gtag('config', tagManagerId);
    });
  }
}

export default {
  name: 'google-analytics',
  initialize,
};
