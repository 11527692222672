import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  ...attributes\n  class=\"contact-list-item-add-list-component\"\n  role=\"button\"\n  {{on \"click\" this.addMailingList passive=true}}\n>\n  {{loc \"Neuen Verteiler-Eintrag erstellen\"}}\n</div>", {"contents":"<div\n  ...attributes\n  class=\"contact-list-item-add-list-component\"\n  role=\"button\"\n  {{on \"click\" this.addMailingList passive=true}}\n>\n  {{loc \"Neuen Verteiler-Eintrag erstellen\"}}\n</div>","moduleName":"ln-liga-os/components/contact-list/item-add-list.hbs","parseOptions":{"srcName":"ln-liga-os/components/contact-list/item-add-list.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class ContactListItemAddListComponent extends Component {
  @action
  addMailingList() {
    this.args.addMailingList?.();
  }
}
