import Evented from '@ember/object/evented';
import RSVP from 'rsvp';
import { isNone } from '@ember/utils';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const defaultValues = {
  NotificationSound: false,
  AlwaysShowCommunicator: false,
};
/**
 * @class SettingsService
 */
export default class SettingsService extends Service.extend(Evented) {
  @service api;
  @service flashMessages;
  @service session;

  @tracked loadPromise;

  @tracked settings = [];

  /**
   *
   * @memberof SettingsService
   * @instance
   *
   * @method getValue
   * @param {String} name
   * @returns {any}
   */
  getValue(name) {
    return this.load().then((settings) => {
      const { value } =
        (settings || []).find((setting) => setting.name === name) || {};

      if (isNone(value)) {
        return defaultValues[name];
      }

      return value;
    });
  }

  /**
   * @memberof SettingsService
   * @instance
   *
   * @method getValue
   * @param {String[]} names
   * @returns {array}
   */
  getValues(names) {
    return RSVP.hash(
      names.reduce((all, name) => {
        all[name] = this.getValue(name);
        return all;
      }, {})
    );
  }

  /**
   * @memberof SettingsService
   * @instance
   *
   * @method setValue
   * @param {String} name
   * @param {any} value
   * @return {Promise<Object>} setting
   */
  setValue(name, value) {
    return this.load().then((settings) => {
      const index = settings.findIndex((setting) => setting.name === name);
      if (index === -1) {
        return this.createSetting(name, value);
      }
      const setting = { ...settings[index], _isDirty: true, value };
      this.settings = [
        ...this.settings.slice(0, index),
        setting,
        ...this.settings.slice(index + 1),
      ];
      return setting;
    });
  }

  createSetting(name, value = null) {
    const setting = {
      id: null,
      version: null,
      name,
      value,
      _isDirty: true,
    };

    this.settings = [...this.settings, setting];

    return setting;
  }

  /**
   * @memberof SettingsService
   * @instance
   *
   * @method saveAllSettings
   * @param {String} name
   * @return {Promise<Object[]>} settings
   */
  saveAllSettings() {
    let hasChangedSettings = false;

    return this.load()
      .then((settings) => {
        const changedSettings = (settings || []).filter(
          ({ _isDirty }) => _isDirty
        );
        hasChangedSettings = changedSettings.length > 0;
        const promises = changedSettings.map((setting) =>
          this.saveSetting(setting)
        );

        return RSVP.all(promises);
      })
      .then(() => {
        if (hasChangedSettings) {
          this.trigger('settings-did-change');
        }

        return this.settings;
      });
  }

  /**
   * @memberof SettingsService
   * @instance
   *
   * @method saveSetting
   * @param {Object} setting
   * @return {Promise<Object>} setting
   */
  saveSetting(setting) {
    const userId = this.session.userId;

    const updatedSetting = { ...setting };
    delete updatedSetting._isDirty;
    const index = this.settings.indexOf(setting);
    if (index !== -1) {
      this.settings = [
        ...this.settings.slice(0, index),
        updatedSetting,
        ...this.settings.slice(index + 1),
      ];
    }
    if (setting.id) {
      return this.api.update(
        'myliga',
        ['users', userId, 'settings', setting.id],
        setting
      );
    }

    return this.api.create('myliga', ['users', userId, 'settings'], setting);
  }

  /**
   * @memberof SettingsService
   * @instance
   *
   * @method load
   * @param {boolean} [refresh=false]
   * @return {Promise<Object[]>} settings
   */
  load(refresh = false) {
    if (refresh || !this.loadPromise) {
      this.loadPromise = this.session.isNewUser
        ? Promise.resolve([])
        : this.api
            .read('vdc', ['current_user', 'settings'])
            .then((response) => {
              this.settings = response.result;
            })
            .catch((error) => {
              this.flashMessages.addMessage('error', 'Settings not loaded.');
              console.error(error);
            });
    }
    return this.loadPromise.then(() => {
      return this.settings;
    });
  }
}
