export default function normalizeErrorResponse(error) {
  let message = null;
  const details = (error.details || [])
    .filter(({ reference }) => reference)
    .reduce((errors, { message, reference }) => {
      errors[reference.replace(/^obj\./, '')] = message;
      return errors;
    }, {});

  if (Object.keys(details).length === 0) {
    const messages = (error.details || [])
      .filter(({ reference, message }) => !reference && message)
      .map(({ message }) => message);

    message = [error.message].concat(messages).join('\n');
  }

  return { message, details };
}
