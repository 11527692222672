import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes class=\"contact-list-item-project-component\">\n  <div class=\"project-item-content\" role=\"button\" {{on \"click\" this.handleClick passive=true}}>\n    <span class=\"project-name\">{{this.project.project_number}} {{this.project.description}}</span>\n    <span class=\"toggle {{if @item.isOpen \"is-open\"}}\" />\n  </div>\n  <span class=\"mail\" role=\"button\" {{on \"click\" this.openProjectMail}} />\n</div>", {"contents":"<div ...attributes class=\"contact-list-item-project-component\">\n  <div class=\"project-item-content\" role=\"button\" {{on \"click\" this.handleClick passive=true}}>\n    <span class=\"project-name\">{{this.project.project_number}} {{this.project.description}}</span>\n    <span class=\"toggle {{if @item.isOpen \"is-open\"}}\" />\n  </div>\n  <span class=\"mail\" role=\"button\" {{on \"click\" this.openProjectMail}} />\n</div>","moduleName":"ln-liga-os/components/contact-list/item-project.hbs","parseOptions":{"srcName":"ln-liga-os/components/contact-list/item-project.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class ContactListItemProjectComponent extends Component {
  @service api;

  get project() {
    return this.args.item.project;
  }

  @action
  handleClick() {
    this.args.onProjectClick?.(this.args.item);
  }

  @action
  openProjectMail(event) {
    event.stopPropagation();
    const query = {
      project: this.project.id,
      show_in_communicator_contact_list: true,
    };

    this.api.read('myliga', ['liga3000_contacts', query]).then((response) => {
      const addresses = (response.result || []).map((contact) => {
        return contact.email;
      });

      window.open(`mailto:${addresses.join(',')}`);
    });
  }
}
