import BaseContactMenuComponent from 'ln-ember-contact-menu/components/contact-menu';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class ContactMenuComponent extends BaseContactMenuComponent {
  @service navigation;
  @service state;

  @action
  openProfile() {
    const url = this.navigation.removeSlug(`/myliga/#/?user=${this.userId}`);
    this.navigation.openUrl(url);
    this.state.hideClickCatcher();
  }
}
