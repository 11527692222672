import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes aria-label={{this.name}} class={{this.className}}>\n  <div class=\"ring\" />\n\n  {{#if @item.icon_url}}\n    <div class=\"icon\" {{css-props this.iconCssProps}} />\n  {{else}}\n    <div class=\"icon\">\n      <span class=\"name\"><span class=\"centered\">{{@item.name}}</span></span>\n    </div>\n  {{/if}}\n</div>", {"contents":"<div ...attributes aria-label={{this.name}} class={{this.className}}>\n  <div class=\"ring\" />\n\n  {{#if @item.icon_url}}\n    <div class=\"icon\" {{css-props this.iconCssProps}} />\n  {{else}}\n    <div class=\"icon\">\n      <span class=\"name\"><span class=\"centered\">{{@item.name}}</span></span>\n    </div>\n  {{/if}}\n</div>","moduleName":"ln-liga-os/components/navigation-item.hbs","parseOptions":{"srcName":"ln-liga-os/components/navigation-item.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class NavigationItemComponent extends Component {
  @service navigation;

  get className() {
    const result = ['navigation-item-component'];
    if (this.isActive) {
      result.push('is-active');
    }
    if (!this.args.item.icon_url) {
      result.push('has-no-icon');
    }
    return result.join(' ');
  }

  get iconCssProps() {
    return {
      'background-image': `url(${this.args.item.icon_url})`,
    };
  }

  get name() {
    return this.args.item.name;
  }

  get isActive() {
    return this.args.item.id === this.navigation.currentItem?.id;
  }
}
