import './deprecation-workflow';
import Application from '@ember/application';
import Resolver from 'ember-resolver';
import { InitSentryForEmber as initSentry } from '@sentry/ember';
import loadInitializers from 'ember-load-initializers';
import config from 'ln-liga-os/config/environment';

if (!['development', 'test'].includes(config.environment)) {
  initSentry({
    release: `${config.APP.name}@${config.APP.version}`,
  });
}

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
