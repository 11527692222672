import { loc } from '@ember/string';

export default function formatErrorMessage(error) {
  let { message: errorMessage = loc('Unknown error') } = error;

  (error.details || []).forEach(
    ({ message }) => (errorMessage += `\n${message}`)
  );
  return errorMessage;
}
