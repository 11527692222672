import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class LoginController extends Controller {
  @service api;
  @service clientTheme;
  @service flashMessages;
  @service router;
  @service session;
  @service storage;

  @tracked btnInvalid = false;

  @tracked invalid = false;

  @tracked keycloackUrl = undefined;

  @tracked message = null;

  @tracked password = null;

  @tracked showPasswordInput = false;

  @tracked showPasswordReset = false;

  @tracked showPasswordResetRequested = false;

  @tracked username = null;

  get passwordInvalid() {
    return this.showPasswordReset && this.invalid && this.btnInvalid;
  }

  get loginInvalid() {
    return this.showLogin && this.invalid && this.btnInvalid;
  }

  get passwordInputType() {
    return this.showPasswordInput ? 'text' : 'password';
  }

  get showLogin() {
    return !this.showPasswordReset && !this.showPasswordResetRequested;
  }

  get loggedIn() {
    return this.session.isLoggedIn;
  }

  get isNespressoEmail() {
    if (!this.keycloackUrl) {
      return undefined;
    }

    if (!this.username) {
      return false;
    }

    return this.username.endsWith('@nespresso.com');
  }

  @action
  goToLogin() {
    this.username = '';
    this.showPasswordReset = false;
    this.showPasswordResetRequested = false;
  }

  @action
  keycloakLogin() {
    return this.session.auth();
  }

  @action
  login() {
    this.resetMessage();

    this.session
      .auth({
        password: this.password || '',
        username: this.username || '',
      })
      .then(() => {
        this.resetMessage();

        this.router.transitionTo('/!');
      })
      .catch((err) => {
        const message = err.message || 'Login failed!';
        this.setError(message);
      });

    return false;
  }

  @action
  passwordReset() {
    this.resetMessage();
    this.api
      .create('myliga', ['security', 'password_resets'], {
        email_or_username: this.username,
      })
      .then(() => {
        this.showPasswordReset = false;
        this.showPasswordResetRequested = true;
      })
      .catch((error) => {
        this.flashMessages.addErrorMessages(error);
      });
  }

  @action
  resetErrorState() {
    this.btnInvalid = false;
  }

  @action
  togglePasswordIcon() {
    this.showPasswordInput = !this.showPasswordInput;
  }

  @action
  togglePasswordReset() {
    this.resetMessage();
    this.password = null;
    this.showPasswordReset = !this.showPasswordReset;
  }

  setError(msg) {
    this.invalid = true;
    this.btnInvalid = true;
    this.message = msg;
  }

  resetMessage() {
    this.invalid = false;
    this.btnInvalid = false;
    this.message = null;
  }
}
