import $ from 'jquery';
import { hash } from 'rsvp';
import { isNone } from '@ember/utils';
import ENV from '../config/environment';

const { BUILD_INFO } = ENV;

function log(...parts) {
  const colored = /(firefox|chrome)/i.test(navigator.userAgent);

  parts = parts.reduce((parts, part) => {
    if (Array.isArray(part)) {
      if (colored && part.length >= 2) {
        parts.push(`%c${part[0]}`);
        parts.push(part[1]);
      } else {
        parts.push(part[0]);
      }
    } else {
      parts.push(part);
    }
    return parts;
  }, []);

  // eslint-disable-next-line no-console
  console.log(...parts);
}

function logInfos(infos) {
  const length = Object.keys(infos).reduce(
    (length, key) => Math.max(length, key.length),
    0
  );

  Object.keys(infos)
    .sort()
    .forEach((key) => {
      const pad = ' '.repeat(length - key.length);
      log([`${key}${pad} :`, 'color: gray;'], infos[key]);
    });
}

function add(target, key, source) {
  target[key] = source[key];
  return target;
}

function logSeparator() {
  log('----------------------------------------------------------');
}

function logBuildInfo() {
  logSeparator();
  log(['[ LIGA OS ]', 'font-weight: bold;']);

  logInfos(
    Object.keys(BUILD_INFO)
      .filter((key) => /^BUILD_.*/.test(key))
      .filter((key) => !isNone(BUILD_INFO[key]))
      .reduce((infos, key) => add(infos, key, BUILD_INFO), {})
  );
}

function logResponseInfo(responses) {
  logSeparator();
  log(['[ API versions ]', 'font-weight: bold;']);
  logInfos(
    Object.keys(responses).reduce((versions, key) => {
      if (typeof responses[key] === 'object') {
        if (responses[key].name && responses[key].version) {
          return { ...versions, [responses[key].name]: responses[key].version };
        }
        return Object.keys(responses[key]).reduce(
          (versions, type) => add(versions, type, responses[key]),
          versions
        );
      }
      return versions;
    }, {})
  );
}

export function initialize(application) {
  const promises = {};

  const apiVdc = application.get('config.api-vdc.url');
  if (apiVdc) {
    // eslint-disable-next-line ember/no-jquery
    promises['api-vdc'] = $.getJSON(`${apiVdc}/v1/version_infos`);
  }
  const apiMyliga = application.get('config.api-myliga.url');
  if (apiMyliga) {
    // eslint-disable-next-line ember/no-jquery
    promises['api-myliga'] = $.getJSON(`${apiMyliga}/v1/version_infos`);
  }

  hash(promises)
    .then((responses) => {
      logBuildInfo();
      logResponseInfo(responses);
      logSeparator();
    })
    .catch(() => {
      logBuildInfo();
      logSeparator();
    });
}

export default {
  name: 'version-info',
  initialize,
};
