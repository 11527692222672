import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  ...attributes\n  class={{this.className}}\n  role=\"button\"\n  {{on \"click\" this.scrollToBottom passive=true}}\n>\n  <svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" width=\"20\" height=\"15\" viewBox=\"0 0 12 10\">\n    <line x1=\"0\" y1=\"5\" x2=\"10\" y2=\"5\" stroke=\"#000\" strike-width=\"1px\"></line>\n    <line x1=\"6\" y1=\"0\" x2=\"11\" y2=\"5\" stroke=\"#000\" strike-width=\"1px\"></line>\n    <line x1=\"6\" y1=\"10\" x2=\"11\" y2=\"5\" stroke=\"#000\" strike-width=\"1px\"></line>\n  </svg>\n</div>", {"contents":"<div\n  ...attributes\n  class={{this.className}}\n  role=\"button\"\n  {{on \"click\" this.scrollToBottom passive=true}}\n>\n  <svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" width=\"20\" height=\"15\" viewBox=\"0 0 12 10\">\n    <line x1=\"0\" y1=\"5\" x2=\"10\" y2=\"5\" stroke=\"#000\" strike-width=\"1px\"></line>\n    <line x1=\"6\" y1=\"0\" x2=\"11\" y2=\"5\" stroke=\"#000\" strike-width=\"1px\"></line>\n    <line x1=\"6\" y1=\"10\" x2=\"11\" y2=\"5\" stroke=\"#000\" strike-width=\"1px\"></line>\n  </svg>\n</div>","moduleName":"ln-liga-os/components/scroll-button.hbs","parseOptions":{"srcName":"ln-liga-os/components/scroll-button.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { schedule } from '@ember/runloop';

export default class ScrollButton extends Component {
  @tracked isVisible = false;

  constructor(...args) {
    super(...args);
    this.setVisibility();
    window.addEventListener(
      'orientationchange',
      this.handleWindowOrientationChange
    );
    schedule('afterRender', this, this.setupScrollingElementEvent);
  }

  get className() {
    const result = ['scroll-button-component'];
    if (this.args.theme) {
      result.push(this.args.theme);
    }
    if (this.isVisible) {
      result.push('is-visible');
    }
    return result.join(' ');
  }

  get scrollingElement() {
    if (!this.args.scrollingElementSelector) {
      return undefined;
    }
    return document.querySelector(this.args.scrollingElementSelector);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.scrollingElement?.removeEventListener('scroll', this.setVisibility);
    window.removeEventListener(
      'orientationchange',
      this.handleWindowOrientationChange
    );
  }

  @action
  handleWindowOrientationChange() {
    schedule('afterRender', this, this.setVisibility);
  }

  @action
  scrollToBottom() {
    this.scrollingElement?.scrollTo({
      behavior: 'smooth',
      top: window.innerHeight,
    });
  }

  @action
  setupScrollingElementEvent() {
    this.scrollingElement?.addEventListener('scroll', this.setVisibility);
  }

  @action
  setVisibility() {
    if (!this.scrollingElement) {
      return;
    }

    this.isVisible =
      window.innerHeight < this.scrollingElement.scrollHeight &&
      this.scrollingElement.scrollTop + window.innerHeight <
        this.scrollingElement.scrollHeight;
  }
}
