import { modifier } from 'ember-modifier';

export default modifier(function cssProps(element, positionalArgs, namedArgs) {
  const props = {
    ...(positionalArgs ?? [])[0],
    ...namedArgs,
  };
  Object.keys(props).forEach((key) => {
    if (props[key] === undefined) {
      return;
    }
    const value = props[key] === null ? null : `${props[key]}`;
    element.style.setProperty(key, value);
  });
  for (let index = 0; index < element.style.length; index++) {
    const key = element.style[index];
    if (props[key] === undefined) {
      element.style.removeProperty(key);
    }
  }
});
