import Service, { inject as service } from '@ember/service';
import Evented from '@ember/object/evented';
import Keycloak from 'ln-liga-os/utils/keycloak';
import config from 'ln-liga-os/config/environment';

export default class SessionService extends Service.extend(Evented) {
  @service api;
  @service state;

  _adapter = null;

  constructor(...args) {
    super(...args);
    const keycloakConfig = config.keycloakPerOrigin
      ? config.keycloakPerOrigin[window.location.origin]
      : null;

    this.isKeycloak = Boolean(keycloakConfig);

    const legacyConfig = {
      api: this.api,
      state: this.state,
    };

    this._adapter = keycloakConfig
      ? new KeycloakAdapter({ ...keycloakConfig, ...legacyConfig })
      : new LegacyAdapter(legacyConfig);
  }

  get content() {
    return this.state.session;
  }

  get isLoggedIn() {
    return !!this.token;
  }

  get isNewUser() {
    return !!this.isLoggedIn && !this.userId;
  }

  get token() {
    return this._adapter.token;
  }

  get userId() {
    return this.content?.user_id;
  }

  checkAuth() {
    return this._adapter.checkAuth();
  }

  auth(...args) {
    return this._adapter.auth(...args).then((result) => {
      this.state.setSession(result);

      return result;
    });
  }

  triggerLogout() {
    const oldUserId = this.userId;

    return Promise.resolve(this.validateSession())
      .then((valid) => {
        if (valid) {
          return this._adapter.logout();
        }
      })
      .then(() => {
        this.state.setSession(null);
        this.trigger('did-logout', oldUserId);
      });
  }

  validateSession() {
    if (!this.token) {
      return Promise.resolve(false);
    }

    return this._adapter.validateSession();
  }

  willDestroy(...args) {
    super.willDestroy(...args);
    this._adapter.destroy?.();
    this._adapter = null;
  }
}

export class AnonymousUserError extends Error {}

class KeycloakAdapter {
  constructor(config) {
    this._keycloak = new Keycloak(config);
    this.state = config.state;
    this._legacyAdapter = new LegacyAdapter({
      api: config.api,
      state: config.state,
    });
    this.location = window.location;
    this._error = null;
  }

  destroy() {
    clearInterval(this._updateTokenInterval);
  }

  /**
   * Checks if the user is authenticated and authorized.
   *
   * @param {boolean} loginRequired - if true, the user will be redirected to the login page if not authenticated yet.
   *
   * @returns {Promise} - resolves with the MyLiga session object + token
   *
   * @rejects {AnonymousUserError} - if the user is authenticated, but not authorized
   * @rejects {Error} - when `loginRequired` is `false` and if the user is not authenticated. Error object has a `status_code` property set to 401.
   */
  checkAuth(loginRequired = false) {
    if (this._keycloak.didInitialize) {
      if (this._error) {
        const error = this._error;
        return Promise.reject(error);
      }

      return Promise.resolve(this.state.session);
    }

    return this._keycloak
      .init({
        onLoad: loginRequired ? 'login-required' : 'check-sso',
        checkLoginIframe: false,
        flow: 'standard',
        // see: https://github.com/keycloak/keycloak/issues/26405#issuecomment-1907880982
        responseMode: 'query',
        // TODO: huh???
        useNonce: false,
      })
      .then((authorized) => {
        if (!authorized) {
          throw new UnauthorizedError();
        }

        this._updateTokenInterval = setInterval(() => {
          this._keycloak.updateToken(-1);
        }, config.keycloakRefreshInterval);

        return this._legacyAdapter
          .checkAuth()
          .then((result) => {
            this.state.setSession(result);

            return result;
          })
          .catch((error) => {
            this.state.setSession(null);

            if (error.status_code === 401) {
              throw new AnonymousUserError();
            }

            throw error;
          })
          .catch((error) => {
            // store the error to be able to reject the next checkAuth call with it
            this._error = error;
            throw error;
          });
      });
  }

  validateSession() {
    return Boolean(this.token);
  }

  auth() {
    if (this._keycloak.didInitialize) {
      return this._keycloak.login();
    } else {
      return this.checkAuth(true);
    }
  }

  logout() {
    this._error = null;
    clearInterval(this._updateTokenInterval);

    let redirectUri = this.location.href;
    const pipeIndex = redirectUri.indexOf('|');
    if (pipeIndex !== -1) {
      redirectUri = redirectUri.substring(0, pipeIndex);
    }
    return this._keycloak.logout({ redirectUri });
  }

  get token() {
    return this._keycloak.token;
  }
}

class LegacyAdapter {
  constructor({ api, state }) {
    this.api = api;
    this.state = state;
  }

  checkAuth() {
    return this._loadUser()
      .catch((error) => {
        if (error.status_code === 401) {
          this.state.setSession(null);
          throw new UnauthorizedError();
        }

        throw error;
      })
      .then((result) => {
        return {
          user_id: result.id,
        };
      });
  }

  _loadUser() {
    return this.api.read('vdc', '/current_user');
  }

  validateSession() {
    return this._loadUser()
      .then(() => true)
      .catch(() => false);
  }

  auth({ username, password }) {
    return this.api
      .create('myliga', '/sessions', {
        password,
        user_name: username,
        app_key: this.api.get('config.api-myliga.app_key'),
      })
      .then(({ user_id, token }) => {
        return {
          user_id,
          token,
        };
      });
  }

  logout() {
    return this.api.delete('myliga', 'sessions');
  }

  get token() {
    return this.state.session?.token;
  }
}

// 401 error
class UnauthorizedError extends Error {
  constructor(message) {
    super(message);
    this.status_code = 401;
  }
}
