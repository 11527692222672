import { assert } from '@ember/debug';
import RSVP from 'rsvp';
import { deprecate } from '@ember/application/deprecations';
import Service, { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isNone } from '@ember/utils';
import { isSupportedBrowser, formatSupportedBrowsers } from '../utils/browser';
import { loc } from '@ember/string';
import config from 'ln-liga-os/config/environment';

const LOG_COLOR = 'green';
const LOG_GROUP_FUNC = 'group';

/**
 * ## Events
 *
 * Apps can listen to events triggered by LIGA OS.
 *
 * **Example**
 *
 * ```JavaScript
 * window.addEventListener('message', (evt) => {
 *   if (evt.data.event === 'my-event-identifier') {
 *     // handle it
 *   }
 * }, false);
 * ```
 *
 * ### Pub Sub Events
 *
 * All Events coming in through the PubSubService are prefixed with `event:`. So if
 * you want to get notified, when the `my-event-identifier` occurs, you need to
 * listen to `event:my-event-identifier`.
 *
 *
 * ## Integration
 *
 * These methods can be called on LigaOsApi Service via `postMessage()` on the
 * parent window.
 *
 * For example:
 *
 * ```JavaScript
 * parent.window.postMessage({idx:'foo-1', method:'logout', args:[] }, '*');
 * ```
 *
 * ```JavaScript
 * window.addEventListener('message', function(evt) {
 *   if (evt.data.idx) {
 *     // data.idx will be the same as provided above
 *     // data.response contains the response.
 *   }
 * }, false);
 * ```
 *
 * See: [ln-subsystem-helper liga-os.js ](https://bitbucket.org/ligadigital/ln-subsystem-helper/src/master/js/liga-os.js)
 *
 * This can be used like this:
 *
 * ```JavaScript
 * ligaOS.call('getLanguage', [], function(token) {
 *   console.log('language:', token);
 * })
 *
 * ligaOS.call('getLanguage', ['en']);
 * ```
 *
 * @class LigaOsApi
 */
export default class LigaOsApiService extends Service {
  @service apiEvents;
  @service clientTheme;
  @service communicator;
  @service navigation;
  @service pubSub;
  @service session;
  @service settings;
  @service state;

  config = config.APP.config;

  constructor(...args) {
    super(...args);
    this.pubSub.on('event', ({ name }) => {
      this.triggerEvent(`event:${name}`);
    });
  }

  // TODO: implement `path` and `queryParams` arguments
  buildUrl(slug) {
    const apps = this.navigation.getApps();
    const app = apps.find((app) => {
      return app.get('slug') === slug;
    });
    if (!app) {
      return;
    }
    // TODO: Deal with "//" vs "/" somehow
    return `${location.origin}/#/!//${slug}/`;
  }

  /**
   * @memberof LigaOsApi
   * @instance
   *
   * @deprecated
   * @method setUrlPath
   * @param {string} url
   */
  setUrlPath(url) {
    this._logDeprecatedCall(
      ['setUrlPath', 'setUrl() or openUrl'],
      '2.3.0',
      ...arguments
    );
    this.navigation.setUrl(url);
  }

  // Navigation
  /**
   * @memberof LigaOsApi
   * @instance
   *
   * @method openUrl
   * @param  {String} url
   */
  openUrl(url) {
    this._logCall('openUrl', ...arguments);
    this.navigation.openUrl(url);
  }

  /**
   * @memberof LigaOsApi
   * @instance
   *
   * @method openResource
   * @param  {String} resource
   * @param  {Number} resourceId
   */
  openResource(resource, resourceId) {
    this._logCall('openResource', ...arguments);
    this.navigation.openResource(resource, resourceId);
  }

  /**
   * This should be called by the App whenever the App's URL changes. It makes
   * sure the URL hash in the browser is up-to-date.
   *
   * @memberof LigaOsApi
   * @instance
   *
   * @method setUrl
   * @param  {String} url
   */
  setUrl(url) {
    this._logCall('setUrl', ...arguments);
    this.navigation.setUrl(url);
  }

  // Config

  /**
   * @memberof LigaOsApi
   * @instance
   *
   * @method getConfig
   * @return {Object}
   */
  getConfig() {
    this._logCall('getConfig', ...arguments);
    return this.config;
  }

  // Session

  /**
   * @memberof LigaOsApi
   * @instance
   *
   * @method getSession
   * @return {Object}
   */
  getSession() {
    this._logCall('getSession', ...arguments);

    return {
      ...this.session.content,
      token: this.session.token,
    };
  }

  /**
   * @memberof LigaOsApi
   * @instance
   *
   * @method validateSession
   * @return {Promise<Boolean>}
   */
  validateSession() {
    this._logCall('validateSession', ...arguments);
    return this.session.validateSession();
  }

  /**
   * Closes the current App and deletes the Session.
   *
   * @memberof LigaOsApi
   * @instance
   *
   * @method logout
   */
  logout() {
    this._logCall('logout', ...arguments);
    this.session.triggerLogout();
  }

  // App state

  /**
   * @memberof LigaOsApi
   * @instance
   *
   * @method getLanguage
   * @return {String}
   */
  getLanguage() {
    this._logCall('getLanguage', ...arguments);
    return this.state.getLanguage();
  }

  /**
   * If the App switches the language this should be called. LIGA OS stores the
   * language token and reloads the browser if needed. The App can than get the
   * current language by calling `getLanguage()`.
   *
   * @memberof LigaOsApi
   * @instance
   *
   * @method setLanguage
   * @param {String} languageCode
   */
  setLanguage(languageCode) {
    this._logCall('setLanguage', ...arguments);
    this.state.setLanguage(languageCode);
  }

  /**
   * @memberof LigaOsApi
   * @instance
   *
   * @method setTitle
   * @param {String} title
   */
  setTitle(title) {
    this._logCall('setTitle', ...arguments);
    this.state.setAppTitle(title);
  }

  /**
   * @memberof LigaOsApi
   * @instance
   *
   * @deprecated
   *
   * @method setTitlePrefix
   * @param {String} prefix
   */
  setTitlePrefix(prefix = null) {
    this._logDeprecatedCall(
      ['setTitlePrefix', 'setTitleCount'],
      '2.3.0',
      ...arguments
    );

    if (/^\d+$/.test(prefix)) {
      this.state.setTitleCount(Number(prefix));
    }
  }

  /**
   * @memberof LigaOsApi
   * @instance
   *
   * @method setTitleCount
   * @param {Number} count
   */
  setTitleCount(count = 0) {
    this._logCall('setTitleCount', ...arguments);
    assert(
      'setTitleCount(): count needs to be a number (integer, greater or equal 0).',
      /^\d+$/.test(count) && Number(count) >= 0
    );
    this.state.setTitleCount(Number(count) || 0);
  }

  /**
   * @memberof LigaOsApi
   * @instance
   *
   * @method hideCommunicator
   * @private
   */
  hideCommunicator() {
    this._logCall('hideCommunicator', ...arguments);
    this.communicator.isVisible = false;
  }

  /**
   * @memberof LigaOsApi
   * @instance
   *
   * @method showCommunicator
   * @private
   */
  showCommunicator() {
    this._logCall('showCommunicator', ...arguments);
    this.communicator.isVisible = true;
  }

  // App Events

  /**
   * This should be called by the App whenever a page finished loading. LIGA OS
   * then removes the Overlay and makes the App visible.
   *
   * @memberof LigaOsApi
   * @instance
   *
   * @method notifyAppLoaded
   */
  notifyAppLoaded() {
    this._logCall('notifyAppLoaded', ...arguments);
    this.state.setAppLoaded(true);
  }

  /**
   * Should be called by the app if the current user has no valid session. LIGA
   * OS closes the app and shows the login screen.
   *
   * @memberof LigaOsApi
   * @instance
   *
   * @method notifyInvalidSession
   */
  notifyInvalidSession() {
    this._logCall('notifyInvalidSession', ...arguments);
    this.session.validateSession().then((valid) => {
      if (!valid) {
        this.session.triggerLogout();
      } else {
        this.state.triggerAppError({
          title: loc('Access Denied!'),
          description: loc(
            'You are missing necessary rights to access this app.'
          ),
        });
      }
    });
  }

  /**
   * Should be called by the app if the current user has not the necessary
   * rights to access the app. LIGA OS closes the app and shows an error.
   *
   * @memberof LigaOsApi
   * @instance
   *
   * @method notifyAccessDenied
   */
  notifyAccessDenied({ message } = {}) {
    this._logCall('notifyAccessDenied', ...arguments);
    this.state.triggerAppError({
      title: loc('Access Denied!'),
      description:
        message || loc('You are missing necessary rights to access this app.'),
    });
  }

  /**
   * @memberof LigaOsApi
   * @instance
   *
   * @method setBlur
   * @param {boolean} state
   */
  setBlur(state) {
    this.state.isBlurred = state;
  }

  /**
   * @memberof LigaOsApi
   * @instance
   *
   * @method checkBrowserSupport
   * @param {Array} supportedBrowsers
   * @return {boolean}
   */
  checkBrowserSupport(supportedBrowsers) {
    this._logCall('checkBrowserSupport', ...arguments);

    if (!isSupportedBrowser(supportedBrowsers)) {
      const description = formatSupportedBrowsers(supportedBrowsers);
      this.state.triggerAppError({
        title: loc('Your browser is not supported.'),
        description: `${loc('Supported browsers are')}: ${description}`,
      });

      return false;
    }

    return true;
  }

  /**
   * @memberof LigaOsApi
   * @instance
   *
   * @method confirm
   * @param {sting} message
   * @return {boolean}
   */
  confirm(message) {
    return window.confirm(message);
  }

  /**
   * @memberof LigaOsApi
   * @instance
   *
   * @method getTheme
   * @param {object} theme
   * @return {object}
   */
  getTheme() {
    this._logCall('getTheme', ...arguments);
    return this.clientTheme.data;
  }

  /**
   * @memberof LigaOsApi
   * @instance
   *
   * @method getSetting
   * @param {String} key
   * @param {any} defaultValue
   * @return {Promise<any>} value
   */
  getSetting(key, defaultValue) {
    return this.settings
      .getValue(key)
      .then((value) => (isNone(value) ? defaultValue : value));
  }

  /**
   * @memberof LigaOsApi
   * @instance
   *
   * @method getTheme
   * @param {String} key
   * @param {any} value
   * @return {Promise<any>} value
   */
  setSetting(key, value) {
    this._logCall('setSetting', ...arguments);

    return this.settings
      .setValue(key, value)
      .then((setting) => this.settings.saveSetting(setting));
  }

  /**
   * @memberof LigaOsApi
   * @instance
   *
   * @private
   * @method triggerEvent
   * @param {String} event
   */
  triggerEvent(event) {
    const iframe = document.querySelector('.app-frame');
    if (iframe) {
      iframe.contentWindow.postMessage({ event }, '*');
    }
  }

  /**
   * @memberof LigaOsApi
   * @instance
   * @private
   *
   * @method _validateMessageOrigin
   * @param {String} origin
   * @return {Boolean}
   */
  _validateMessageOrigin(origin) {
    const allowedApps = this.navigation.getApps();

    // Trust everything that runs on the same domain
    if (origin === window.location.origin) {
      return true;
    }

    const isAllowed = allowedApps.some((app) => {
      return app.url.origin.indexOf(origin) === 0;
    });

    return isAllowed;
  }

  /**
   * @memberof LigaOsApi
   * @instance
   *
   * @private
   * @method register
   */
  register() {
    window.addEventListener('message', this.handleWindowMessage);
  }

  // Helper functions

  _logCall(func, ...args) {
    if (!this.config.log?.ligaOSApi?.call) {
      return;
    }

    args = args.filter((arg) => typeof arg !== 'function');

    if (args.length > 0) {
      /* eslint-disable no-console */
      console[LOG_GROUP_FUNC](
        `%c[liga-os-api] ${func}()`,
        `color:${LOG_COLOR};`
      );
      args.forEach((arg) => console.log(arg));
      console.groupEnd('');
    } else {
      console.log(
        `%c[liga-os-api] ${func}()`,
        `color:${LOG_COLOR}; font-weight: bold;`
      );
      /* eslint-enable no-console */
    }
  }

  _logResponse(response) {
    if (!this.config.log?.ligaOSApi?.response) {
      return;
    }

    if (response) {
      /* eslint-disable no-console */
      console[LOG_GROUP_FUNC](
        '%c=>',
        `color: ${LOG_COLOR}; font-weight: bold;`
      );
      console.log(response);
      console.groupEnd();
      /* eslint-enable no-console */
    }
  }

  _logDeprecatedCall(funcArg, until, ...args) {
    if (!this.config.log?.ligaOSApi?.deprecated) {
      return;
    }

    if (typeof funcArg === 'string') {
      funcArg = [funcArg];
    }

    const [func, funcReplace] = funcArg;

    this._logCall(func, ...args);
    deprecate(
      `[liga-os-api] ${func}() is deprecated ${
        funcReplace ? ` use ${funcReplace}() instead` : ''
      }.`,
      null,
      {
        id: `liga-os-api:${func
          .replace(/([A-Z][a-z]+|[a-z]+)([A-Z])/g, '$1-$2')
          .toLowerCase()}`,
        until,
      }
    );
  }

  willDestroy() {
    window.removeEventListener('message', this.handleWindowMessage);
  }

  @action
  handleWindowMessage(event) {
    const {
      origin,
      source,
      data: { idx, method, args },
    } = event;

    const allowedOrigin = this._validateMessageOrigin(origin);
    if (!allowedOrigin) {
      console.error('Untrusted origin:', origin);
    }

    if (source && idx && method && allowedOrigin) {
      if (typeof this[method] === 'function') {
        RSVP.resolve(this[method](...(args || [])))
          .then((response) => {
            this._logResponse(response);
            source.postMessage({ idx, response }, '*');
          })
          .catch((error) => console.error('[liga-os-api] error', error));
      } else {
        console.error('[liga-os-api] Invalid call', { idx, method, args });
      }
    }
  }
}
