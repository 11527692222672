import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes class=\"communicator-filter-base-component\">\n  <div class=\"filter-action\" data-value={{@value}}>\n    {{#each @options as |option|}}\n      <span {{on \"click\" (fn @setFilter option.value) passive=true}}\n        class=\"filter-tab {{if (eq @value option.value) \"active\"}} {{option.class}}\"\n        data-test-value={{option.value}}\n        role=\"button\"\n      >\n        {{option.label}}\n      </span>\n    {{/each}}\n  </div>\n\n  <hr {{css-props this.hrCssProps}}>\n</div>", {"contents":"<div ...attributes class=\"communicator-filter-base-component\">\n  <div class=\"filter-action\" data-value={{@value}}>\n    {{#each @options as |option|}}\n      <span {{on \"click\" (fn @setFilter option.value) passive=true}}\n        class=\"filter-tab {{if (eq @value option.value) \"active\"}} {{option.class}}\"\n        data-test-value={{option.value}}\n        role=\"button\"\n      >\n        {{option.label}}\n      </span>\n    {{/each}}\n  </div>\n\n  <hr {{css-props this.hrCssProps}}>\n</div>","moduleName":"ln-liga-os/components/communicator/filter.hbs","parseOptions":{"srcName":"ln-liga-os/components/communicator/filter.hbs"}});
import Component from '@glimmer/component';

export default class CommunicatorFilterComponent extends Component {
  get hrCssProps() {
    const index = (this.args.options ?? []).findIndex((filter) => {
      return filter.value === this.args.value;
    });
    if (index === -1) {
      return { width: 0 };
    }
    const xPercent = index * 100;
    const widthPercent = 100 / this.args.options.length;
    return { transform: `translateX(${xPercent}%)`, width: `${widthPercent}%` };
  }
}
