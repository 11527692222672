import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  ...attributes\n  class={{this.className}}\n  {{did-update this.loadData @filter}}\n  {{on \"scroll\" this.handleScroll passive=true}}\n>\n  <SearchInput @value={{this.searchTerm}} @onChange={{this.searchValueDidChange}} />\n\n  <div class=\"fade-in-content\">\n    {{#each this.notifications as |notification|}}\n      <Communicator::NotificationItem\n        @filter={{this.filter}}\n        @notification={{notification}}\n        @onOptionClick={{this.option}}\n        @openNotification={{this.openNotification}}\n        data-test-element=\"notification-item\"\n      />\n    {{/each}}\n  </div>\n\n  {{#if this.isLoading}}\n    <LoadingSpinner data-test-element=\"loading-spinner\" />\n  {{else}}\n    {{#if (eq this.notifications.length 0)}}\n      <div class=\"list-message\">[ {{loc \"No entries\"}} ]</div>\n    {{/if}}\n  {{/if}}\n</div>", {"contents":"<div\n  ...attributes\n  class={{this.className}}\n  {{did-update this.loadData @filter}}\n  {{on \"scroll\" this.handleScroll passive=true}}\n>\n  <SearchInput @value={{this.searchTerm}} @onChange={{this.searchValueDidChange}} />\n\n  <div class=\"fade-in-content\">\n    {{#each this.notifications as |notification|}}\n      <Communicator::NotificationItem\n        @filter={{this.filter}}\n        @notification={{notification}}\n        @onOptionClick={{this.option}}\n        @openNotification={{this.openNotification}}\n        data-test-element=\"notification-item\"\n      />\n    {{/each}}\n  </div>\n\n  {{#if this.isLoading}}\n    <LoadingSpinner data-test-element=\"loading-spinner\" />\n  {{else}}\n    {{#if (eq this.notifications.length 0)}}\n      <div class=\"list-message\">[ {{loc \"No entries\"}} ]</div>\n    {{/if}}\n  {{/if}}\n</div>","moduleName":"ln-liga-os/components/communicator/tab-notifications.hbs","parseOptions":{"srcName":"ln-liga-os/components/communicator/tab-notifications.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { compare } from '@ember/utils';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class CommunicatorTabNotifications extends Component {
  @service communicator;
  @service navigation;
  @service session;
  @service store;

  @tracked hasMore = true;

  @tracked isContentVisible = false;

  @tracked isLoading = true;

  @tracked lastRequest = null;

  @tracked notifications = [];

  @tracked pagingLimit = 20;

  @tracked searchTerm = '';

  lastRequestHash = null;

  constructor(...args) {
    super(...args);
    this.loadData();
  }

  get className() {
    const result = [
      'communicator-tab-base-component',
      'communicator-tab-notifications-component',
      'list',
      'notifications-list',
      `filter-${this.args.filter}`,
    ];
    if (this.isLoading) {
      result.push('is-loading');
    }
    if (this.isContentVisible) {
      result.push('is-content-visible');
    }
    return result.join(' ');
  }

  @action
  handleScroll(event) {
    const target = event.target;
    const scrollBottom = target.scrollTop + target.offsetHeight;
    const scrollHeight = target.scrollHeight;

    if (scrollBottom + scrollHeight * 0.25 >= scrollHeight) {
      // reached end of list
      this.loadData();
    }
  }

  @action
  openNotification(notification) {
    if (notification.get('link')) {
      this.navigation.openUrl(notification.get('link'));
      this.close();
      return;
    }

    const resource = notification.get('context_type');
    const resourceId = notification.get('context_id');

    if (this.navigation.canOpenResource(resource, resourceId)) {
      this.navigation.openResource(resource, resourceId);
      this.close();
    }
  }

  @action
  option(optionKey, notification) {
    const keyPropMap = {
      toggleRead: 'read_state',
      toggleFlag: 'flagged',
      toggleArchive: 'archived',
    };
    const prop = keyPropMap[optionKey];
    if (!prop) {
      return;
    }

    notification.set(prop, !notification.get(prop));
    notification.save();
  }

  @action
  searchValueDidChange(value) {
    this.searchTerm = value;
    this.loadData(true);
  }

  @action
  loadData(reload = false) {
    if (!this.session.isLoggedIn) {
      return;
    }

    if (reload) {
      this.notifications = [];
      this.hasMore = true;
    }

    if (!this.hasMore) {
      return;
    }

    const query = this.createQuery({
      paging_limit: this.pagingLimit,
      paging_offset: this.notifications.length,
    });
    const lastRequestHash = JSON.stringify(query);

    if (this.isLoading && this.lastRequestHash === lastRequestHash) {
      return;
    }

    this.lastRequestHash = lastRequestHash;
    this.isLoading = true;

    const request = this.store
      .query('vdc/notification', query)
      .then((notifications) => {
        if (this.isDestroying || request !== this.lastRequest) {
          return;
        }

        this.notifications = [
          ...this.notifications,
          ...notifications
            .slice()
            .sort((a, b) => compare(b.get('datetime'), a.get('datetime'))),
        ];

        this.hasMore = this.pagingLimit === notifications.meta.result_count;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        if (this.isDestroying || request !== this.lastRequest) {
          return;
        }

        this.isLoading = false;
        this.isContentVisible = true;
      });

    this.lastRequest = request;
  }

  createQuery(add = {}) {
    const query = {};

    switch (this.args.filter) {
      case 'current':
        Object.assign(query, { archived: false, current: true });
        break;
      case 'flagged':
        Object.assign(query, { archived: false, flagged: true });
        break;
      case 'unread':
        Object.assign(query, { archived: false, read_state: false });
        break;
      case 'archive':
        Object.assign(query, { archived: true, current: false });
        break;
    }

    const term = this.getProperSearchTerm();
    if (term !== '') {
      Object.assign(query, { search_text: term });
    }

    return Object.assign(query, add);
  }

  getProperSearchTerm() {
    let term = this.searchTerm || '';
    const numChars = term.replace(/\s+/g, '').length; // without whitespace
    if (numChars <= 2) {
      term = '';
    }
    return term;
  }

  updateUnreadCountBadge() {
    this.communicator.updateBadges(false);
  }
}
