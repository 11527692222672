import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class PasswordResetController extends Controller {
  get token() {
    return this.model.token;
  }

  @action
  goToLogin() {
    this.transitionToRoute('login');
  }
}
