import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import {
  decodeHashPart,
  encodeHashPart,
  replaceLocationHash,
} from 'ln-liga-os/utils/url';
import formatErrorMessage from 'ln-liga-os/utils/format-error-message';
import config from 'ln-liga-os/config/environment';

export default class MainRoute extends Route {
  @service apiEvents;
  @service clientTheme;
  @service communicator;
  @service flashMessages;
  @service microMenu;
  @service navigation;
  @service pubSub;
  @service rights;
  @service router;
  @service session;
  @service settings;
  @service state;

  isPendingUser = false;

  previousUrl = null;

  previousUserId = null;

  beforeModel(transition) {
    return this.session
      .checkAuth()
      .catch((error) => {
        if (error.status_code === 401) {
          this.previousUrl = transition.to.params.url;

          this.onDidLogout(this.session.content?.user_id);
        }

        throw error;
      })
      .then(() => {
        return this.rights.rights.then((rights) => {
          this.isPendingUser = rights.length === 0;
        });
      })
      .catch((error) => {
        if (this.session.isNewUser) {
          const ssoSignupApp = config.APP.config.apps.find(
            (app) => app.slug === 'sso-signup'
          );

          if (ssoSignupApp) {
            this.navigation.set('navigations', [
              {
                name: 'Sign up',
                url: ssoSignupApp.url,
              },
            ]);

            return;
          }
        }

        throw error;
      });
  }

  model({ url }) {
    const previousUrl = this.popPreviousUrl();
    if (previousUrl) {
      url = previousUrl;
      // '!' means no app selected
    } else if (!url || (url === '!' && !this.session.isNewUser)) {
      url = this.getLastVisitedUrl() || '!';
    }

    try {
      // Replace encoded pipes.
      replaceLocationHash(/%7C/g, '|');
    } catch (error) {
      // mute
    }

    if (this.isPendingUser) {
      this.state.triggerAppError({
        description: 'Please wait for your application to be reviewed',
        title: 'User review pending',
      });
      return;
    }

    return Promise.resolve(
      this.session.content ? this.state.loadUser() : undefined
    )
      .then(() => this.navigation.loadNavigations())
      .then(() => {
        const appUrl = this.decodeRoute(url);

        const currentRouteUrl = this.encodeRoute(this.navigation.currentUrl);
        if (
          currentRouteUrl &&
          currentRouteUrl !== '!' &&
          currentRouteUrl !== url
        ) {
          this.navigation.openUrl(appUrl);
        }

        if (appUrl) {
          if (!this.navigation.setUrl(appUrl)) {
            return;
          }

          // upon the initial login into the app, the `activate` hook wasn't called yet
          this.onUrlDidChange(appUrl);
        } else {
          this.navigation.openFirstItem();
        }

        this.navigation.loadedAppCheck();
        this.state.setAppTitle(this.navigation.currentItem?.name);
      })
      .catch((error) => {
        const errorMessage = formatErrorMessage(error);
        this.flashMessages.addMessage('error', errorMessage);
        console.error(error);
      });
  }

  activate() {
    this.pubSub.subscribe();
    this.navigation.on('url-did-change', this, this.onUrlDidChange);
    this.session.on('did-logout', this, this.onDidLogout);
    this.state.on('language-did-change', this, this.onLanguageDidChange);
    this.apiEvents.on('error-response', this, this.onApiErrorResponse);

    if (!this.session.isNewUser) {
      this.communicator.subscribe();
    }
  }

  setupController(...args) {
    super.setupController(...args);

    this.microMenu.enable();
  }

  deactivate() {
    this.pubSub.unsubscribe();
    this.communicator.unsubscribe();
    this.navigation.off('url-did-change', this, this.onUrlDidChange);
    this.session.off('did-logout', this, this.onDidLogout);
    this.state.off('language-did-change', this, this.onLanguageDidChange);
    this.apiEvents.off('error-response', this, this.onApiErrorResponse);

    document.body.classList.remove('supports-micro-menu');
  }

  encodeRoute(url) {
    url = encodeHashPart(url);
    url = this.navigation.addSlug(url);

    return url;
  }

  decodeRoute(url) {
    if (!url) {
      return null;
    }

    url = this.navigation.removeSlug(url);

    return decodeHashPart(url);
  }

  onDidLogout(userId) {
    this.previousUserId = userId;

    this.state.reset();

    this.router.transitionTo('login');
  }

  onUrlDidChange(url) {
    const encodedUrl = this.encodeRoute(url);

    this.replaceWith(`/${encodedUrl}`);
    this.saveLastVisitedUrl(encodedUrl);
  }

  getLastVisitedUrl() {
    return sessionStorage.getItem('last-app-url');
  }

  saveLastVisitedUrl(url) {
    sessionStorage.setItem('last-app-url', url);
  }

  onLanguageDidChange() {
    window.location.reload();
  }

  popPreviousUrl() {
    const url = this.previousUrl;
    const userId = this.previousUserId;

    this.previousUrl = null;
    this.previousUserId = null;

    // If previous request was without a session or from the same user.
    if (url && (!userId || userId === this.session.userId)) {
      return url;
    }
  }

  onApiErrorResponse(response) {
    if (this.session.isLoggedIn && response.status === 401) {
      this.session.triggerLogout();
    }
  }
}
