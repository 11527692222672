import { computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isNone } from '@ember/utils';
import { later, scheduleOnce } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';
import { loc } from '@ember/string';
import CommunicatorTabItem from 'ln-liga-os/objects/communicator-tab-item';

export default class CommunicatorService extends Service {
  @service api;
  @service rights;
  @service session;
  @service state;

  @tracked badges = {};

  @tracked canAccessMailingLists = false;

  @tracked isVisible = true;

  pollingIntervalTime = 60000;

  constructor(...args) {
    super(...args);
    if (this.session.isLoggedIn && !this.session.isNewUser) {
      this.rights.can('READ', 'EmailDistributor').then((canAccess) => {
        this.canAccessMailingLists = canAccess;
      });
    }
  }

  @computed('session.isLoggedIn', 'tabsConf.@each.{badge,hasAccess}')
  get badgeNumber() {
    if (!this.session.isLoggedIn) {
      return undefined;
    }
    const allowedTabs = this.tabsConf.filter((tab) => tab.hasAccess);
    return allowedTabs.reduce((a, b) => a + (b.badge || 0), 0);
  }

  get currentTab() {
    return this.tabsConf.find((tab) => tab.active);
  }

  @computed('canAccessMailingLists', 'session.{isLoggedIn,isNewUser}')
  get tabsConf() {
    if (!this.session.isLoggedIn || this.session.isNewUser) {
      return [];
    }

    const contactsFilterOptions = [
      { label: loc('All'), value: 'all' },
      { label: loc('Projects'), value: 'projects' },
    ];
    if (this.canAccessMailingLists) {
      contactsFilterOptions.push({ label: loc('Lists'), value: 'lists' });
    }

    return [
      { identifier: 'applications', hasRight: true },
      {
        identifier: 'notifications',
        filterOptions: [
          { label: loc('Inbox'), value: 'current' },
          { label: loc('Flagged'), value: 'flagged' },
          { label: loc('Unread'), value: 'unread' },
          { label: loc('Archive'), value: 'archive' },
        ],
        filterValue: 'current',
      },
      {
        identifier: 'tasks',
        filterOptions: [
          { label: loc('All'), value: 'all' },
          { label: loc('Urgent'), value: 'urgent' },
          { label: loc('Today'), value: 'today' },
          { label: loc('Upcoming'), value: 'coming' },
        ],
        filterValue: 'all',
      },
      {
        identifier: 'contacts',
        filterOptions: contactsFilterOptions,
        filterValue: 'all',
      },
      {
        identifier: 'absences',
        filterOptions: [
          { label: loc('All'), value: 'all' },
          { class: 'absence', value: 'Sickness,Holiday' },
          { class: 'travel', value: 'Travel' },
        ],
        filterValue: 'all',
      },
      {
        identifier: 'birthdays',
        filterOptions: [
          { label: loc('All'), value: 'all' },
          { label: loc('Today'), value: 'today' },
          { label: loc('Upcoming'), value: 'coming' },
        ],
        filterValue: 'all',
      },
      { identifier: 'settings', hasTitle: true, hasRight: true },
    ].map((tab) => {
      return new CommunicatorTabItem(this, tab);
    });
  }

  handleNotificationOpened() {
    if (this.badges.notifications > 0) {
      this.api
        .update('vdc', '/notifications/opened')
        .then(() => this.updateBadges(false));
    }
  }

  setCurrentTab(tab, delay = 0) {
    this.tabsConf.forEach((iTab) => {
      iTab.rendered = false;
      iTab.active = false;
    });
    tab.active = true;
    later(() => {
      tab.rendered = true;
      if (tab.identifier === 'notifications') {
        this.handleNotificationOpened();
      }
    }, delay);
  }

  reset() {
    this.badges = {};
  }

  subscribe() {
    // TODO switch to pubSub
    this.startPolling();
  }

  unsubscribe() {
    this.stopPolling();
  }

  // TODO remove polling add Pubsub!
  startPolling() {
    this.stopPolling();

    this.updateBadges();
    const interval = setInterval(
      () => this.updateBadges(),
      this.pollingIntervalTime
    );
    this.pollingInterval = interval;
  }

  stopPolling() {
    const interval = this.pollingInterval;
    if (interval) {
      clearInterval(interval);
      this.pollingInterval = null;
    }
  }

  updateBadges(playSound = true) {
    if (!this.session.isLoggedIn) {
      return;
    }

    const currentBadges = Object.assign({}, this.badges);

    return this.api
      .read('myliga', '/communicator_badges')
      .then((badges) => {
        if (JSON.stringify(currentBadges) === JSON.stringify(badges)) {
          return;
        }

        this.badges = badges;
      })
      .catch((err) => {
        console.error(err);
      })
      .then(() =>
        scheduleOnce(
          'afterRender',
          this,
          this._maybeTriggerNotificationSound,
          playSound,
          currentBadges
        )
      )
      .catch((err) => {
        console.error(err);
      });
  }

  _maybeTriggerNotificationSound(playSound, currentBadges) {
    // Trigger notification sound if one of the badges is greater that before.
    const { badges } = this;
    playSound =
      playSound &&
      Object.keys(badges).find(
        (key) => !isNone(currentBadges[key]) && badges[key] > currentBadges[key]
      );

    if (playSound) {
      this.state.triggerNotificationSound();
    }
  }
}
