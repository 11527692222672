import BaseApplicationAdapter from 'ln-ember-data-models/adapters/application';
import { inject as service } from '@ember/service';

export default class ApplicationAdapter extends BaseApplicationAdapter {
  @service session;

  loadToken() {
    return this.session.token;
  }
}
