import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  ...attributes\n  class=\"search-input-component {{if this.hasValue 'has-value'}}\"\n>\n  <input id={{this.inputId}} type=\"text\" value={{@value}} {{on \"input\" this.handleInput passive=true}} />\n  <button type=\"button\" {{on \"click\" this.clear passive=true}} />\n</div>", {"contents":"<div\n  ...attributes\n  class=\"search-input-component {{if this.hasValue 'has-value'}}\"\n>\n  <input id={{this.inputId}} type=\"text\" value={{@value}} {{on \"input\" this.handleInput passive=true}} />\n  <button type=\"button\" {{on \"click\" this.clear passive=true}} />\n</div>","moduleName":"ln-liga-os/components/search-input.hbs","parseOptions":{"srcName":"ln-liga-os/components/search-input.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { guidFor } from '@ember/object/internals';

export default class SearchInputComponent extends Component {
  inputId = `input-${guidFor(this)}`;

  get hasValue() {
    return !!this.args.value;
  }

  @action
  clear() {
    const element = document.getElementById(this.inputId);
    element.value = '';
    element.dispatchEvent(new InputEvent('input'));
    element.focus();
  }

  @action
  handleInput(event) {
    debounce(this, this.args.onChange, event.target.value, 250);
  }
}
